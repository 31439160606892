import React from "react";
import * as S from "./style";

interface Props {
  content?: string
}

const ErrorSpan = (props: Props) => {
  const { content } = props;
  return (
    <S.Content>{content}</S.Content>
  );
}

export default ErrorSpan;
