/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-02-17 11:02:12
 * @modify date 2022-02-17 11:02:12
 * @desc [description]
 */

import { mainAxios } from "libs/axios";

/** Article Group Sale */
interface ArticleGroupSaleProps {
  page?: number | "";
  size?: number | "";
  query?: string | "";
  startTime?: any | "";
  endTime?: any | "";
  categoryCode?: string | "GPNOTICE";
}

export const getArticleGroupSale = (payload: ArticleGroupSaleProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: "/article/get-all",
    payload: payload,
  });
};

/** Get Group Sale List*/
interface GroupSaleListProps {
  querySearch?: string;
  /** UPCOMING: sắp diễn ra,
      ON_GOING: đang tiến hành,
      WAITING_DONE: đợi hoàn thành,
      ENDED: đã hoàn thành | đã kết thúc */
  typeSearch?: "UPCOMING" | "ON_GOING" | "WAITING_DONE" | "ENDED";
  page?: number | 1;
  size?: number | 10;
}

export const getGroupSaleList = (params: GroupSaleListProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: "/gp/find",
    params: params,
    notError: true,
    getError: true,
  });
};

/** Get Group Sale List*/
interface GroupSaleDetailProps {
  id?: number;
}

export const getGroupSaleDetail = (params: GroupSaleDetailProps) => {
  return mainAxios.request({
    methodType: "GET",
    requiresToken: true,
    url: "/gp/gp-detail",
    params: params,
  });
};

/** Have Slot */

interface OnSaleOrderProps {
  address?: string;
  bankAccount?: string;
  bankHolderName?: string;
  bankName?: string;
  buyerName?: string;
  email?: string;
  id?: string;
  incomeDeduction?: string;
  invoiceInfo?: string;
  invoiceType?: string;
  note?: string;
  phone?: string;
  zipCode?: string;
  pointUsing?: number;
  groupPurchaseId?: number;
  unitBuy?: number;
  getInvoice?: boolean;
}

export const postFormHaveSlot = (
  isArtWaiting: boolean,
  payload: OnSaleOrderProps
) => {
  return mainAxios.request({
    methodType: "POST",
    requiresToken: true,
    url: isArtWaiting ? "/gp/join-gp-waiting" : "/gp/join-gp",
    payload: {
      ...payload,
      isArtWaiting: undefined,
    },
  });
};

/** Check has the order when go to detail page*/
interface CheckStatusOrderProps {
  groupPurchaseId?: number;
}

export const checkStatusOrder = (params: CheckStatusOrderProps) => {
  return mainAxios.request({
    methodType: "GET",
    requiresToken: true,
    url: "/gp/my-order",
    params: params,
  });
};
