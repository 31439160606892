import { mainAxios } from "libs/axios";

export const getPopupApi = () => {
  return mainAxios.request({
    methodType: "POST",
    url: `/popup/get-all`,
    notError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
