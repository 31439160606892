/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:23:06
 * @modify date 2022-01-19 14:23:06
 * @desc [description]
 */

import { Empty } from "antd";
/** Config */
import { UrlInternal } from "common/constants/endpoints";
import useWindowSize from "hooks/useWindowSize";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react"; //lazy
import { useHistory } from "react-router-dom";
/**img */
import ImgGift from "assets/images/_main_slider/gift.png";
/** Ant Lib*/
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getTimeRemaning } from "utils/getTime";
import Countdown from "react-countdown";
/** Utils */
import moneyConvert from "utils/moneyConvert";
/** Components*/
import CircleProgress from "../CircularProgress";
/** Style Local */
import * as S from "./style";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  data?: any;
}

//props: Props
const MainSlider = ({ data = [] }: Props) => {
  const history = useHistory();
  const slider: any = useRef<any>(null);
  const { width }: any = useWindowSize();
  const [showItem, setShowItem] = useState<any>({ show: false, item: "" });
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 500,
  };

  /** Next to slide */
  const nextToSlide = useCallback(() => {
    slider.current.slickNext();
  }, []);

  /** Back to slide */
  const backToSlide = useCallback(() => {
    slider.current.slickPrev();
  }, []);

  /** Show info on mobile size*/
  const showInfo = (show: any, item: number) => {
    setShowItem({ show, item });
  };

  const prefixO = (dataNumber: number) => {
    return dataNumber < 10 ? `0${dataNumber}` : dataNumber;
  };

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <div>
          {hours <= 0 && minutes <= 0 && seconds <= 0 ? (
            <></>
          ) : (
            <span className="time-event">
              {" "}
              ({prefixO(hours)} : {prefixO(minutes)} : {prefixO(seconds)})
            </span>
          )}
        </div>
        // <span>
        //   {hours <= 0 && minutes <= 0 && seconds <= 0 ? <></> : prefixO(hours)}{" "}
        //   : {prefixO(minutes)} : {prefixO(seconds)}
        // </span>
      );
    }
  };

  const handleShowCountDown = (endDate: string) => {
    const remaningDay = getTimeRemaning(moment().toISOString(), endDate);

    if (remaningDay > 0) {
      return `${remaningDay}${t("D")}`;
    } else {
      return (
        <>
          {remaningDay}
          {t("D")}
          <Countdown date={new Date(endDate).getTime()} renderer={renderer} />
        </>
      );
    }
  };

  const handleCheckTimeEndEvent = (eventEndDate: string) => {
    if (eventEndDate) {
      let presentDate = moment(moment().toISOString(), "YYYY-MM-DD HH:mm:ss");
      let lastDate = moment(eventEndDate, "YYYY-MM-DD HH:mm:ss");
      let result = lastDate.diff(presentDate);
      return result <= 0 ? false : true;
    } else {
      return true;
    }
  };

  const renderDataSlider = () => {
    let dt = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (handleCheckTimeEndEvent(data[i].eventEndDate)) {
          dt.push(data[i]);
        }
      }
    }
    return dt;
  };

  const changLangSubCategory = (valueSubCategory: string) => {
    switch (valueSubCategory) {
      case "스타터":
        return t("STARTER");
      case "비기너":
        return t("BEGINNER");
      case "메이저":
        return t("MAJOR");
      case "프리오더":
        return t("PREORDER");
      case "게릴라":
        return t("GUARILLA");
      case "EVENT_RB":
        return t("HOME_DRAW_EVENT");
      default:
        return;
    }
  };

  return (
    <>
      {renderDataSlider()?.length > 0 && (
        <S.Container className="main-slider">
          <Slider ref={slider} {...settings} useTransform={false}>
            {renderDataSlider()?.map((v: any, i: any) => (
              <S.SlideItem key={i}>
                <div
                  className={`item item-left ${
                    showItem?.show && showItem?.item === i ? "showInfo" : ""
                  }`}
                  onClick={() => (width <= 768 ? showInfo(true, i) : () => {})}
                >
                  <div className="item-left__title">{v?.title}</div>
                  <div className="item-left__title author">{v?.authorName}</div>
                  <div className="item-left__art">
                    {v?.thumbnail ? (
                      <img src={v?.thumbnail} alt="art" />
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No Image"
                      />
                    )}
                  </div>
                  <div className="item-left__artist">{v?.authorName}</div>
                  <div className="item-left__size">
                    {`${v?.artSize ? v?.artSize?.trim() + "," : ""} ${
                      v?.artMaterial
                        ? v?.artMaterial?.trim() + (v?.yearOfItem ? "," : "")
                        : ""
                    } ${v?.yearOfItem?.trim()}`}
                  </div>
                  {data.length > 1 && (
                    <div
                      className="ti ti-arrow-circle-left back"
                      onClick={backToSlide}
                    ></div>
                  )}
                  <div
                    className="open-information"
                    onClick={() => showInfo(!showItem?.show, i)}
                  >
                    <span>Details</span>
                    {/* <span className="ti ti-angle-double-right"></span> */}
                  </div>
                </div>
                <div className="item-line"></div>
                <div
                  className="item item-right"
                  onClick={() =>
                    width <= 768 ? showInfo(!showItem?.show, i) : () => {}
                  }
                >
                  <div className="item-right__title">
                    {v?.isUpcomming
                      ? t("GROUP_SALE_TITLE_UPCOMING")
                      : t("ONGOING")}
                  </div>
                  <div className="item-right__tab">
                    <div className="item-right__tab-item active">
                      {/* {v?.subCategory === "EVENT_RB"
                        ? t("HOME_DRAW_EVENT")
                        : v?.subCategory} */}
                      {changLangSubCategory(v?.subCategory)}
                    </div>

                    <div className="item-right__tab-item">
                      {v?.isUpcomming
                        ? `${t("START_DATE")}\t:\t`
                        : `${t("REMAINING_TIME")}\t:\t`}
                      {v?.isUpcomming
                        ? moment(v?.startDate).format("YYYY.MM.DD HH:mm")
                        : handleShowCountDown(
                            v?.eventType === "EVENT_RB"
                              ? v?.eventEndDate
                              : v?.endDate
                          )}
                    </div>
                  </div>
                  {v?.eventType === "EVENT_RB" ? (
                    <>
                      <img src={ImgGift} alt="img gift" />
                      <div className="item-right__box-price">
                        <div className="item-right__box-price--title">
                          {t("HOME_PRICE")}
                        </div>
                        <div className="item-right__box-price--price">
                          {moneyConvertLang(v?.target)}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="item-right__chart">
                        <CircleProgress
                          percentage={v?.targetPercent}
                          content={
                            <S.Content>
                              <span className="percentage">
                                {v?.targetPercent}%
                              </span>
                              <span className="chart-line"></span>
                              <span className="chart-content">
                                {t("SLIDER_PROGRESS")}
                              </span>
                              <span className="chart-content">
                                {t("SLIDER_PROGRESS1")}
                              </span>
                            </S.Content>
                          }
                          color="#5d7160"
                        />
                      </div>
                      <div className="item-right__price">
                        <div className="item-right__price-left">
                          <span className="bold">{t("HOME_PRICE")}</span>
                          <span>{moneyConvertLang(v?.target)}</span>
                        </div>
                        <div className="item-right__price-line"></div>
                        <div className="item-right__price-right">
                          <span className="bold">{t("PURCHASING_PRICE")}</span>
                          <span>{moneyConvertLang(v?.currentMoney)}</span>
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    className="item-right__btn"
                    onClick={() =>
                      history.push(
                        `${
                          v?.isUpcomming
                            ? UrlInternal.GROUP_SALE_COMMING
                            : UrlInternal.GROUP_SALE_ON_ORDER
                        }/${v?.id}`
                      )
                    }
                  >
                    {t("HOME_JOIN")}
                    {` `}
                    <i className="ti ti-angle-right"></i>
                  </button>
                  {data.length > 1 && (
                    <div
                      className="ti ti-arrow-circle-right next"
                      onClick={nextToSlide}
                    ></div>
                  )}
                </div>
              </S.SlideItem>
            ))}
          </Slider>
        </S.Container>
      )}
    </>
  );
};

export default MainSlider;
