/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-21 13:51:26
 * @modify date 2022-01-21 13:51:26
 * @desc [description]
 */

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"; //
import types from "store/actions/types";

// import { UrlInternal } from "common/constants/endpoints";

/** Config */
import { moneyFormatRound, moneyConvertToNumber } from "utils/moneyConvert";

import * as S from "./style";

interface Props {
  placeHl: string;
}

const SearchLeftMenu = ({ placeHl }: Props) => {
  const dispatch = useDispatch();
  const stateFilter = useSelector((state: any) => state.filterLeftMenu);
  const [price, setPrice] = useState<any>({
    startPrice: stateFilter?.startPrice,
    endPrice: stateFilter?.endPrice,
  });
  const { t } = useTranslation();

  /** Get Search Value */
  const getSearchValue = () => {
    const v: any = document.getElementById("searchInput");
    dispatch({
      type: types.FILTER_ON_LEFT_MENU,
      payload: {
        ...stateFilter,
        querySearch: v?.value.trim() ?? "",
      },
    });
  };

  /** Get Status filter Value */
  const getSatusFilterValue = (e: any) => {
    dispatch({
      type: types.FILTER_ON_LEFT_MENU,
      payload: {
        ...stateFilter,
        typeSearch: e.target.value,
      },
    });
  };

  /** Get Price filter Value */
  const getPriceData = useCallback(
    (data: any) => {
      if (data?.type === "startPrice")
        setPrice({ ...price, ...{ startPrice: data?.value } });
      if (data?.type === "endPrice")
        setPrice({ ...price, ...{ endPrice: data?.value } });
    },
    [price]
  );

  const getPriceFilterValue = () => {
    let startPrice: any = price?.startPrice
      ? moneyConvertToNumber(price?.startPrice)
      : undefined;
    let endPrice: any = price?.endPrice
      ? moneyConvertToNumber(price?.endPrice)
      : undefined;

    dispatch({
      type: types.FILTER_ON_LEFT_MENU,
      payload: {
        ...stateFilter,
        startPrice,
        endPrice,
      },
    });
  };

  useEffect(() => {
    stateFilter.querySearch = undefined;
    stateFilter.typeSearch = undefined;
    stateFilter.startPrice = undefined;
    stateFilter.endPrice = undefined;
    setPrice({
      startPrice: undefined,
      endPrice: undefined,
    });
  }, []);

  return (
    <S.Container>
      {/** Price Filter */}
      <S.PriceFilter>
        <S.InputFilter
          id="startPrice"
          className="min-price-filter"
          placeholder={t("ART_MARKET_LOW_PRICE")}
          // defaultValue={stateFilter?.startPrice}
          value={moneyFormatRound(price?.startPrice ?? "", true)}
          onChange={(e: any) =>
            getPriceData({ type: "startPrice", value: e?.target?.value })
          }
          autoComplete="off"
        />
        <span className="to">~</span>
        <S.InputFilter
          id="endPrice"
          className="max-price-filter"
          placeholder={t("ART_MARKET_HIGH_PRICE")}
          // defaultValue={stateFilter?.endPrice}
          value={moneyFormatRound(price?.endPrice ?? "", true)}
          onChange={(e: any) =>
            getPriceData({ type: "endPrice", value: e?.target?.value })
          }
          autoComplete="off"
        />
        <S.PriceFilterBTN onClick={getPriceFilterValue}>
          {t("ART_MARKET_APPLY")}
        </S.PriceFilterBTN>
      </S.PriceFilter>

      {/** Status Filter */}
      <S.StatusFilter
        onChange={getSatusFilterValue}
        // defaultValue={stateFilter?.typeSearch}
      >
        <S.StatusFilterBTN
          name="all"
          value={undefined}
          className="onsale-status"
        >
          {t("ART_MARKET_ALL")}
        </S.StatusFilterBTN>
        <S.StatusFilterBTN
          name="onsale"
          value={"AVAILABLE"}
          className="onsale-status"
        >
          {t("ART_MARKET_AVAILABLE")}
        </S.StatusFilterBTN>
        <S.StatusFilterBTN
          name="pending"
          value={"OUTSTOCK"}
          className="pending-status"
        >
          {t("ART_MARKET_SOLD_OUT")}
        </S.StatusFilterBTN>
      </S.StatusFilter>

      {/** Search Filter */}
      <S.Content className="search-on-left-menu">
        <S.SearchInput
          id="searchInput"
          placeholder={placeHl}
          onBlur={getSearchValue}
          onKeyDown={(e: any) => {
            if (e?.keyCode === 13) getSearchValue();
          }}
          // defaultValue={stateFilter?.querySearch}
          autoComplete="off"
        />
        <S.SearchButton
          type="text"
          icon={<span className="ti ti-search"></span>}
          onClick={getSearchValue}
        />
      </S.Content>
    </S.Container>
  );
};

export default SearchLeftMenu;
