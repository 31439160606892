/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-24 15:52:38
 * @modify date 2022-01-24 15:52:38
 * @desc [description]
 */

import styled from "styled-components";

export const CardContent = styled.div`
  flex: 0 0 calc(25% - 35.25px);
  padding: 30px 0;
  @media screen and (max-width: 1024px) {
    padding: 26px 0;
    flex: 0 0 calc(33% - 20px);
  }
  @media screen and (max-width: 768px) {
    padding: 22px 0;
    flex: 0 0 calc(50% - 10px);
  }
  @media screen and (max-width: 539px) {
    padding: 20px 0;
    flex: 0 0 calc(100%);
  }

  .container {
    /* width: 400px; */
    height: 100%;
    max-width: 314px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .image-card {
      width: 100%;
      /* height: 314px; */
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15% 0;
      max-height: 295px;
      img {
        max-width: 200px;
        height: 200px;
        object-fit: fill;
        @media screen and (max-width: 767px) {
          max-width: 180px;
          height: 180px;
        }
      }
    }
  }
`;

export const ActionCard = styled.div`
  width: 100%;
  height: 61.5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: right;
  border-bottom: 1px solid #cfcece;

  span {
    font-size: 23px;
    color: #c2c2c2;
    cursor: pointer;

    &:last-child {
      margin-left: 36px;
      &.active {
        color: #ad9a6d;
      }
    }
  }
`;

export const CardMeta = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px 0px 0px 0px;
  justify-content: flex-start;
  width: 100%;
`;

export const CardArtName = styled.span`
  width: 100%;
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
  text-align: left;
  color: #5c7161;
  margin-top: 30px;
`;

export const CardTitle = styled.span`
  width: 100%;
  font-family: "Montserrat-Light";
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #5c7161;
  margin: 10px 0px 19px 0px;
  text-align: left;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CardPrice = styled.span`
  width: 100%;
  text-align: left;
  font-family: "Montserrat-Bold";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.5px;
  color: #5c7161;
  font-weight: 600;
  margin-top: auto;

  .old-price {
    font-family: "Montserrat-Regular";
    font-size: 16px;
    text-decoration: line-through;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 425px) {
      font-size: 16px;
    }
  }

  .current-price {
    margin-left: 7px;
  }

  .sale-person {
    font-family: "Montserrat-Regular";
    margin-left: 10px;
    color: #de8808;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 425px) {
      font-size: 20px;
    }
  }
`;

export const BtnGroup = styled.div``;
