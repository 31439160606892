/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:33:01
 * @modify date 2022-01-19 14:33:01
 * @desc [description]
 */

import styled from "styled-components";

interface ItemProps {
  bg?: any;
}

export const ProductSlider = styled.div`
  width: 561.9px;
  .slick-dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: initial;
    margin-top: 32px;
    justify-content: space-between;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 35px 0px 0px;

      &:last-child {
        margin: 0px;
      }

      &.slick-active {
        /* &:nth-child(1) {
          margin: 0px 23px 0px 0px;
        } */

        span {
          color: #111111;
          font-family: "NotoSansKR-Bold";
        }
      }
    }
  }
`;

export const SlideItem = styled.div<ItemProps>`
  /* height: 750px; */
  height: auto;
  color: #ffffff;
  /* background: #08ba98; */
  display: flex !important;
  flex-direction: column;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  cursor: pointer;
`;

export const SlideItemImg = styled.img`
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
`;

export const RowDots = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Dots = styled.div`
  display: flex;
  /* justify-content: space-between; */
`;

export const DotsItem = styled.span`
  font-family: "NotoSansKR-Regular";
  color: #bcbcbc;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionBackNext = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
`;

export const BackArrow = styled.span`
  color: #111111;
  font-size: 22px;
  margin-right: 36px;
  cursor: pointer;
`;

export const NextArrow = styled.span`
  color: #111111;
  font-size: 22px;
  cursor: pointer;
`;
