/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-24 11:53:41
 * @modify date 2022-01-24 11:53:41
 * @desc [description]
 */

import React from "react";
import { Empty, Image, Progress } from "antd";

/** Utils */
import moneyConvert from "utils/moneyConvert";

/** Images */
import art_market_slider_1 from "assets/images/_art_market_slider/art_market_slider_1.png";

/** Local Style */
import * as S from "./style";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  type?: "percent" | "default";
  image: string;
  title: string;
  priceSale?: number;
  price?: number;
  className?: string;
  gotToDetail?: () => void;
  // Sale End
  isSaleEnd?: boolean;
  targetPercent?: number;
  currentMoney?: number;
  startDate?: string;
  eventType?: string;
  isSaleComing?: boolean;
}

const ArtCardItem = ({
  type = "default",
  image = art_market_slider_1,
  title,
  priceSale,
  price,
  className,
  gotToDetail,
  //sale coming
  isSaleComing,
  // Sale End
  isSaleEnd = false,
  targetPercent = 0,
  currentMoney = 0,
  startDate,
  eventType,
}: Props) => {
  const { t } = useTranslation();

  function checkPercentColor() {
    if (targetPercent < 80) return "#5c7161";
    if (targetPercent >= 80 && targetPercent < 100) return "#de8808";
    if (targetPercent === 100) return "#8b14bc";
  }

  function checkPercentTextColor() {
    if (targetPercent < 100) return "#000";
    if (targetPercent === 100) return "#ffffff";
  }

  return (
    <S.CardContent className={`art-card-item ${className}`}>
      <div className="container-card">
        <S.CardContentImg
          onClick={gotToDetail}
          active={gotToDetail ? true : false}
        >
          {image ? (
            <Image src={image} alt={"art"} preview={false} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Image"
            />
          )}
        </S.CardContentImg>
        {/**sale coming */}
        {isSaleComing && eventType === "EVENT_RB" ? (
          <S.TagEvent>EVENT</S.TagEvent>
        ) : (
          <S.TagEvent noData />
        )}

        {!isSaleComing && eventType === "EVENT_RB" ? (
          <S.TagEvent>EVENT</S.TagEvent>
        ) : (
          <>
            {type === "percent" && (
              <S.Process textColor={checkPercentTextColor()}>
                <Progress
                  percent={targetPercent}
                  strokeColor={checkPercentColor()}
                  trailColor="#f2f2f2"
                  showInfo={false}
                />
                <span className="infor-progress">{targetPercent}%</span>
              </S.Process>
            )}
          </>
        )}
        {/* <S.CardMeta> */}
        <S.CardTitle className={isSaleEnd ? "sale-end-title" : ""}>
          {title}
        </S.CardTitle>
        {isSaleEnd ? (
          <S.SaleEnd>
            <span>
              {t("GROUP_SALE_END_ACHIEVEMENT_RATE")}
              {` `}
              <span className="sale-end-bold">
                {moneyConvert(targetPercent)}%
              </span>
            </span>
            <span>
              {t("GROUP_SALE_END_PURCHASING_PRICE")}
              {` `}
              <span className="sale-end-bold">
                {moneyConvertLang(currentMoney)}
              </span>
            </span>
            <span>
              {t("GROUP_SALE_END_DATE_JOINT_CHASE")} {` `}
              <span className="sale-end-bold">
                {moment(startDate).format("YYYY.MM.DD")}
              </span>
            </span>
          </S.SaleEnd>
        ) : (
          <S.CardPrice>
            {price && (
              <span className="old-price">{moneyConvertLang(price)}</span>
            )}
            {moneyConvertLang(priceSale)}
          </S.CardPrice>
        )}
        {/* </S.CardMeta> */}
      </div>
    </S.CardContent>
  );
};

export default ArtCardItem;
