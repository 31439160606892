/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-04-20 11:15:12
 * @modify date 2022-04-20 11:15:12
 * @desc [description]
 */

import { Button, Modal, Row } from "antd";
import React from "react";
import * as S from "./style";
import Popup_Head from "assets/images/_popup/popup_head.png";

interface PopupProps {
  visible: boolean;
  setVisible?: () => void;
  type?: "not_sale" | "default";
  contentNotice?: any;
  confirmEvent?: () => void;
  confirmGroupSale?: boolean;
  isWaitingBuy?: boolean;
  eventType?: string;
}

const Popup: React.FC<PopupProps> = ({
  visible,
  setVisible,
  type = "default",
  contentNotice,
  confirmEvent,
  confirmGroupSale,
  isWaitingBuy,
  eventType,
}) => {
  const handleRenderText = () => {
    if (eventType === "EVENT_RB") {
      return (
        <h2>
          응모 신청완료했습니다.
          <br />
          마이페이지에서 확인할 수 <br />
          있습니다.
        </h2>
      );
    } else if (!isWaitingBuy) {
      return <h2>주문 완료되었습니다.</h2>;
    } else {
      return (
        <h2>
          {contentNotice ?? (
            <span>
              대기 신청이 <br /> 접수되었습니다.
            </span>
          )}
        </h2>
      );
    }
  };

  return (
    <>
      <Modal
        bodyStyle={{ minHeight: 234, position: "relative", padding: 0 }}
        visible={visible}
        width={400}
        closable={false}
        footer={null}
        onCancel={() => setVisible?.()}
      >
        <S.Container className={`${type}`}>
          <S.HeadPopup src={Popup_Head} alt="head-popup" />
          <S.Content>
            {handleRenderText()}
            {/* {!isWaitingBuy ? (
              <h2>주문 완료되었습니다.</h2>
            ) : (
              <h2>
                {contentNotice ?? (
                  <span>
                    대기 신청이 <br /> 접수되었습니다.
                  </span>
                )}
              </h2>
            )} */}
            <Row justify="center">
              <Button type="primary" onClick={confirmEvent}>
                확인
              </Button>
            </Row>
          </S.Content>
        </S.Container>
      </Modal>
    </>
  );
};

export default Popup;
