
import React from "react";
import { PopupMyPage } from "components";

import * as S from "./style";

export interface PopupProps {
  visible: boolean;
  setVisible: () => void;
  okEvent?: () => void;
}

const ModalSuccess: React.FC<PopupProps> = ({ visible, setVisible }) => {
  return (

    <>
      <PopupMyPage
        visible={visible}
        content={
          <S.Content>
            재입고 신청 <br />
            되었습니다
          </S.Content>
        }
        okEvent={() => setVisible()}
        setVisible={() => setVisible()}
        onlyOk />
    </>
  );
};

export default ModalSuccess;
