/**
 * @author [Peter]
 * @email [hoangvanlam9988@mail.com]
 * @create date 2022-04-18 15:45:40
 * @modify date 2022-04-18 15:45:40
 * @desc [description]
 */

import React from "react";
import moment from "moment";

/** Utils */
// import moneyConvert from "utils/moneyConvert";

import { ReactComponent as Next } from "assets/icons/next.svg";

/** Local Style */
import * as S from "./style";

interface Props {
  data?: any;
  goToDetail?: () => void;
}

const ArtNewSletterCard = ({
  data,
  goToDetail,
}: Props) => {

  return (
    <S.CardContent>
      <div className="card-head">
        <div className="card-head__title">NEWSLETTER</div>{/*{data?.title}*/}
        <div className="card-head__more" onClick={goToDetail}><Next /></div>
      </div>
      <div className="card-date">
        <div className="date">{!data?.createdAt ? "" : moment(data?.createdAt).format("MMM yyyy")}</div>{/**MAY 2022 */}
        <div className="vol">Vol {data?.newsletterVol}</div>
      </div>
      <div className="card-info">
        <div className="card-info__title"><span>{data?.newsletterTitle1}</span></div>
        <div className="card-info__content">
          <span>{data?.newsletterSummary1}</span>
        </div>

        <div className="card-info__title"><span>{data?.newsletterTitle2}</span></div>
        <div className="card-info__content">
          <span>{data?.newsletterSummary2}</span>
        </div>
      </div>
    </S.CardContent>
  );
};

export default ArtNewSletterCard;
