/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-21 13:48:53
 * @modify date 2022-01-21 13:48:53
 * @desc [description]
 */

import { Button, Radio } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 820px) {
    > div {
      margin: 10px 0px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #5c7161;
  transition: all 1s;
  margin-left: 32px;
  padding-left: 15px;
  @media screen and (max-width: 1366px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 1059px) {
    margin-left: 0;
    /* padding-left: 0; */
    margin-top: 20px;
    width: 100%;
  }

  @media screen and (max-width: 820px) {
    margin-top: 0;
    width: 100%;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Montserrat-Light";
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;

  &:active,
  :focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.13px;
    text-align: left;
    color: #858585;
  }
`;

export const SearchButton = styled(Button)`
  width: 35px;
  height: 35px;
  background-color: #5c7161;
  border-radius: 0px;
  padding: 0px 10px;

  &:active,
  :hover,
  :focus {
    background-color: #e8e9da;
  }

  span {
    font-size: 16px;
    color: white;
  }
`;

export const StatusFilter = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-left: 32px;
  @media screen and (max-width: 820px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StatusFilterBTN = styled(Radio.Button)`
  /* width: 135px; */
  width: 33%;
  min-width: 100px;
  height: 35px;
  text-align: center;
  @media screen and (max-width: 450px) {
    width: auto;
    min-width: auto;
    flex: 1;
  }

  &.ant-radio-button-wrapper {
    background-color: transparent;
    font-family: "Montserrat-Regular";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border: 1px solid #5c7161;
    color: #5c7161;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: gray;
      border: 1px solid #5c7161;
    }

    &:active,
    :focus {
      border: none;
      color: #5c7161;
      font-weight: 600;
    }

    &:nth-child(2),
    :last-child {
      &::before {
        width: 0px;
      }
    }
  }

  &.ant-radio-button-wrapper-checked {
    background-color: #5c7161;
    color: #fff;
    border: none;
    text-align: center;
    font-family: "Montserrat-SemiBold";
    font-weight: 600;
  }
`;

export const PriceFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 820px) {
    width: 100%;
    flex-wrap: wrap;
  }

  .price-filter-title {
    font-family: "NotoSansKR-Light";
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: 0.38px;
    text-align: left;
    color: #111111;
    margin-bottom: 9px;
  }

  .to {
    margin: auto 6px;
  }
`;

export const InputFilter = styled.input`
  height: 35px;
  border: solid 1px #e8e9da;
  outline: none;
  padding-left: 20px;

  @media screen and (max-width: 768px) {
    flex: 1;
  }

  @media screen and (max-width: 560px) {
    flex: 1;
    @media screen and (max-width: 432px) {
      width: calc(100% - 150px);
    }
  }

  &::placeholder {
    font-family: "Montserrat-Medium";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #858585;
  }
`;

export const PriceFilterBTN = styled(Button)`
  width: 100px;
  height: 35px;
  background-color: #e8e9da;
  display: block;
  margin: 0px 0px 0px 10px;
  font-family: "Montserrat-SemiBold";
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.38px;
  text-align: center;
  color: #5c7161;
  border: none;
  @media screen and (max-width: 560px) {
    width: 100%;
    margin: 10px 0 0px;
  }

  &:hover,
  :focus,
  :active {
    background-color: #5c7161;
    color: #ffffff;
    border: none;
  }
`;
