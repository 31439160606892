import { Table } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  /**table */
  .ant-table-wrapper {
    .ant-table-cell {
      font-family: "Montserrat-Regular";
      font-size: 16px;
      letter-spacing: 0.4px;
      text-align: center;
      color: #5d7160;
    }
    .ant-table-header {
      .ant-table-cell {
        font-family: "Montserrat-SemiBold";
        font-size: 18px;
        font-weight: 600;
        &::before {
          content: none !important;
        }
      }
      .ant-table-thead > tr > th {
        border: none;
        background-color: #eff0e3;
        padding: 27px 8px;
        @media screen and (max-width: 768px) {
          padding: 27px 5px;
        }
      }
    }
    .ant-table-body {
      cursor: pointer;
      .ant-table-tbody > tr > td {
        border-bottom: 0;
        border-right: solid 1px #c8c8c8;

        &:nth-child(2) {
          padding: 11px 30px;
          @media screen and (max-width: 768px) {
            padding: 11px 20px;
          }
        }
        padding: 13px 10px;
        /* &:last-child {
          border: none;
        } */
      }
      /* .ant-table-row {
        &:nth-child(odd) {
          background-color: #f5f5f5;
        }
      } */
    }
    .row-style-1 {
      background-color: #f5f5f5;
    }
  }
  /** pagination */
  .ant-pagination {
    margin-top: 42px;
    align-items: center;
    gap: 0px;
    /* @media screen and (max-width: 425px) {
      gap: 0;
    } */
    .ant-pagination-next,
    .ant-pagination-prev {
      svg {
        margin-top: 9px;
      }
    }

    .ant-pagination-item {
      border: none;
      /* font-family: "Montserrat-Regular"; */
      /* margin: 0 -13.5px; */
      a {
        font-size: 15px;
        color: #111;
        font-family: "Montserrat-Regular";
        margin-top: 0.8px;
        :hover {
          color: #1890ff;
        }
      }
    }
    .ant-pagination-item-active {
      border: solid 1px #5d7160;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #5d7160;
      line-height: 31px;
      a {
        color: #5d7160;
        font-family: "Montserrat-Regular";
        margin-top: -0.2px;
        padding: 0;
      }
    }
    .ant-pagination-item-ellipsis {
      top: 30%;
    }
    .ant-pagination-options {
      display: none;
    }
  }
`;
