import { getCategoriesMarketApi } from "apis";
import types from "./types";

const getDataMarket = () => async (dispatch: any) => {
  try {
    const { data }: any = await getCategoriesMarketApi();

    dispatch({
      type: types.GET_CATEGORY_MARKET,
      dataCategoriesMarket: data,
    });
  } catch (error) {}
};
export default { getDataMarket };
