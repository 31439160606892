/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-05-11 10:58:02
 * @modify date 2022-05-11 10:58:02
 * @desc [description]
 */

import React from "react";
import moneyConvert from "utils/moneyConvert";

import * as S from "./style";

interface Props {
  children?: any;
}

function NumberRun({ children }: Props): React.ReactElement {
  const counters: any = document.querySelectorAll(".number-run");
  counters.forEach((counter: any) => {
    counter.innerText = "0";
    const updateCounter = () => {
      const target = +counter.getAttribute("data-target");
      const count = +counter.innerText;
      const increment = target / 200;
      if (count < target) {
        counter.innerText = `${Math.ceil(count + increment)}`;
        setTimeout(updateCounter, 30);
      } else counter.innerText = moneyConvert(target, true);
    };
    updateCounter();
  });

  children = children ? children?.replace(/\,/g, "") : 0;

  return (
    <S.Container className="number-run" data-target={children}>
      {/* {children} */}
    </S.Container>
  );
}

export default NumberRun;
