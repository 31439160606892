/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-24 15:52:38
 * @modify date 2022-01-24 15:52:38
 * @desc [description]
 */

import styled from "styled-components";

export const CardContent = styled.div`
  min-width: 617px;
  min-height: 319px;
  /* max-height: 319px; */
  overflow: hidden;
  padding: 44px 33.5px 49.5px 0px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.59);
  background-color: #5a6e5f;

  .card-head {
    display: flex;
    gap: 16px;
    align-items: center;

    &__num {
      min-width: 104px;
      height: 43px;
      background-color: #fff;
      font-size: 21px;
      font-family: "Montserrat-Bold";
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5c7161;
    }

    &__title {
      max-height: 43px;
      overflow: auto;

      span {
        font-family: "Montserrat-Bold";
        font-size: 31px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: -1.24px;
        text-align: left;
        color: #fff;
        overflow: auto;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      @media (max-width: 540px) {
        overflow: hidden;
      }

      @media (max-width: 425px) {
        display: inline-flex;
        flex-direction: column;
        span {
          font-size: 20px;
          letter-spacing: normal;
        }
      }
    }
  }

  .card-content {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.5px;
    color: #fff;
    margin: 36px 0px 0px 29px;

    &__author {
      font-family: "Montserrat-Medium";
      border-bottom: solid 1px #fff;
      height: 56px;
      line-height: 56px;
      overflow: hidden auto;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    &__saleprice {
      font-family: "Montserrat-Medium";
      border-bottom: solid 1px #fff;
      padding: 6.5px 0px;
    }
    &__more {
      border-bottom: solid 1px #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10.5px 0px;

      span {
        font-family: "Montserrat-Medium";
      }
    }

    &__place {
      margin-top: 8.5px;
      font-family: "Montserrat-Medium";
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.32px;
      text-align: right;
      color: #fff;
    }

    @media (max-width: 540px) {
      margin: 18px 0px 0px 26px;
      font-size: 20px;

      &__author {
        padding: 0px 0px 12.5px 0px;
        min-height: 37.5px;
      }

      &__saleprice {
        padding: 11.5px 0px;
      }

      &__more {
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        padding: 10.5px 0px 13.5px 0px;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }

  @media (max-width: 425px) {
    min-width: 100%;
    width: 100%;
    max-height: 347px;
    padding: 44px 34.1px 57.5px 0px;
  }
`;
