import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./style";

interface Props {
  listTabs: string[];
  handleActive?: any;
  isCompartment?: any;
}

function Tabs({
  listTabs,
  handleActive,
  isCompartment = true,
}: Props): React.ReactElement {
  const [tabActive, setTabActive] = useState<number>(0);
  const { t } = useTranslation();

  const handleTabs = (value: number) => {
    setTabActive(value);
    handleActive?.(value ?? 0);
  };

  return (
    <>
      {listTabs &&
        listTabs?.map((item, index) => (
          <S.Tabs
            onClick={() => handleTabs(index)}
            className={`tab${index} ${tabActive === index ? "active" : ""}`}
            key={index}
            isCompartment={isCompartment}
          >
            {t(item)}
          </S.Tabs>
        ))}
    </>
  );
}

export default Tabs;
