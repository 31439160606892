import styled from "styled-components";
import { Button } from "antd";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10400;
  width: 100vw;
  height: 100vh;
  background-color: #899e8c;
  opacity: 0.63;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10500;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  .img {
    margin-bottom: 30px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
  @media screen and (max-width: 320px) {
    margin-right: 10px;
  }

  &.header {
    justify-content: space-between;
    /* border-bottom: solid 1px #5d7160; */
    .title {
      font-family: "NotoSansKR-Bold";
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #5d7160;
    }
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  z-index: 100;
  padding: 17px 25px 35px 25px;
  margin: 15vh auto 0;
  max-width: 470px;
  min-height: 554px;
  vertical-align: middle;
  p {
    text-align: center;
  }
  @media (max-width: 768px) {
    max-width: 330px;
  }
  @media (max-width: 360px) {
    max-width: 330px;
  }
  @media screen and (max-width: 280px) {
    width: 100%;
    padding: 17px 0px 35px 0px;
    border-radius: 0px;
    /* max-width: 250px; */
  }
  &.height-default {
    min-height: 230px;
  }
`;

export const ModalTitle = styled.p`
  color: #1d1f21;
  font-family: "NotoSansKR-Bold";
  font-size: 20px;
  position: relative;
  margin-bottom: 15px;
`;

export const ModalContent = styled.div`
  color: #8d8d8d;
  font-size: 16px;
  font-family: "NotoSansKR-Bold";
  margin-bottom: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5d7160;
  line-height: 1.69;
  flex: 1;
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ModalCloseButton = styled.div`
  border: none;
  background-color: inherit;
  cursor: pointer;
  .anticon-close {
    svg {
      width: 22px;
      height: 22px;
      fill: #5d7160;
    }
  }
`;

export const ModalButton = styled.div`
  display: flex;
  justify-content: center;
  button {
    font-family: "NotoSansKR-Bold";
    font-size: 14px;
    padding: 15px 0;
    border-radius: 5px;
    text-align: center;
  }
`;

export const ModalCancel = styled.button`
  border: solid 2px #1d1f21;
  color: #1d1f21;
  width: 136px;
  margin-right: 10px;
  background-color: white;
  @media screen and (max-width: 320px) {
    width: 115px;
  }
`;

export const ModalConfirm = styled.button`
  background-color: #1d1f21;
  color: white;
  border: none;
  width: 140px;
  @media screen and (max-width: 320px) {
    width: 115px;
  }
`;

export const ButtonStyled = styled(Button)`
  text-align: center;
  width: 197px;
  height: 40px;
  margin: 0 auto 50px;
  border-radius: 5px;
  background-color: #5d7160;
  font-family: NotoSansKR-Bold;
  font-size: 13px;
  color: #fff;
  border: none;
  &:hover {
    background-color: #5d7160;
    color: #fff;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background-color: #5d7160;
  }
`;

export const ButtonCancel = styled(Button)`
  text-align: center;
  width: 197px;
  height: 40px;
  margin: 0 auto 50px;
  border-radius: 5px;
  background-color: #e8fffb;
  font-family: NotoSansKR-Bold;
  font-size: 13px;
  color: #5d7160;
  border: none;
  &:hover {
    background-color: #e8fffb;
    color: #5d7160;
  }
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-bottom: 0;
  }
`;
