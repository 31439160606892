import styled from "styled-components";

export const WrapperForm = styled.div`
  min-width: 330px;
  margin-bottom: 42px;
  @media screen and (max-width: 768px) {
    min-width: 0;
  }
  h2 {
    font-family: "Montserrat-SemiBold";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-align: center;
    color: #5c7161;
    margin: 0 0 25px;
  }
  p {
    font-family: "Montserrat-Light";
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: #5c7161;
  }
  .ant-form-large .ant-form-item-label > label {
    font-family: "Montserrat-Light";
    height: unset;
    color: #5c7161;
  }
  input {
    height: 50px;
    &::placeholder {
      font-family: "Montserrat-Light";
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: #d1d1d1;
    }
  }
  #stockPhone,
  #stockQuantity {
    width: 100%;
    font-family: "NotoSansKR-Light";
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.4px;
    color: #111;
    padding: 13px;
    line-height: 22px;
    border: 1px solid #d9d9d9;
    &:focus-visible {
      outline: none;
    }
  }
`;
