import React, { useState, useEffect } from "react";
import * as S from "./style";

interface Props {
  listTabs: string[];
  handleActive?: any;
  isCompartment?: any;
  activeDefault?:any;
}

function TabsMobile({ listTabs, handleActive, isCompartment = true, activeDefault }: Props): React.ReactElement {
  const [tabActive, setTabActive] = useState<number>(0);

  useEffect(() => {
    if(activeDefault){
      setTabActive(activeDefault)
    }
  }, [ ]);

  const handleTabs = (value: number) => {
    setTabActive(value);
    handleActive?.(value ?? 0);
  };

  return (
    <>
      <S.TabsMenu>
        {listTabs &&
          listTabs?.map((item, index) => (
            <S.Tabs
              onClick={() => handleTabs(index)}
              className={`tab${index} ${tabActive === index ? "active" : ""}`}
              key={index}
              isCompartment={isCompartment}
            >
              {item}
            </S.Tabs>
          ))}
      </S.TabsMenu>
    </>
  );
}

export default TabsMobile;
