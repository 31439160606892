/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:23:06
 * @modify date 2022-01-19 14:23:06
 * @desc [description]
 */

import React, { useEffect, useState } from "react"; //lazy
// import { useHistory } from "react-router-dom";

/** Components */
import { Heading, ProgressBar } from "components"; //ProductSlider

/** Config */
import moneyConvert from "utils/moneyConvert";

/** Style Local */
import * as S from "./style";
import { useCountdown } from "hooks/useCountdown";
import { useHistory, useParams } from "react-router-dom";
import { UrlInternal } from "common/constants/endpoints";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  headingTitle?: string;
  artName?: string;
  artStatus?: string;
  artProgress?: number;
  artTotalAmount?: number;
  artTargetAmount?: number;
  artTimeRemaining?: number;
  actionButtonDisable?: boolean;
  actionButtonTitle?: string;
  actionButtonEvent?: () => void;
  goToDetail?: () => void;
  data?: any;
  listSubImage?: any;
  groupSaleComming?: boolean;
  startAt?: string;
  eventType?: string;
}

function SaleGroup({
  headingTitle,
  artName,
  artStatus,
  artProgress = 0,
  artTotalAmount = 0,
  artTargetAmount = 0,
  artTimeRemaining = 0,
  actionButtonDisable = false,
  actionButtonTitle,
  actionButtonEvent,
  goToDetail,
  listSubImage,
  groupSaleComming,
  startAt,
  eventType,
}: Props) {
  const history = useHistory();
  const { id }: any = useParams();
  const [currentItem, setCurrentItem] = useState<any>({
    id: 0,
    data: listSubImage[0] ?? "",
  });
  const [days, hours, minutes, seconds] = useCountdown(
    new Date(startAt ?? new Date()).getTime(),
    artTimeRemaining
  );
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentItem({
      id: 0,
      data: listSubImage[0] ?? "",
    });
  }, [listSubImage]);

  const prefixO = (dataNumber: number) => {
    return dataNumber < 10 ? `0${dataNumber}` : dataNumber;
  };

  useEffect(() => {
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      history.push(`${UrlInternal.GROUP_SALE_ON_ORDER}/${id}`);
    }
  }, [days, hours, minutes, seconds]);

  return (
    <S.SaleTogetherContainer className="sale-group">
      {/* <ProductSlider listArt={listSubImage} goToDetail={goToDetail} /> */}
      <S.ListImage>
        {/* <div> */}
        {listSubImage?.map((v: any, i: number) => (
          <S.ItemtImage
            className={currentItem.id === i ? "active" : ""}
            key={i}
            src={v}
            alt="image-item"
            onClick={() => setCurrentItem({ id: i, data: v })}
          />
        ))}
        {/* </div> */}
      </S.ListImage>
      <S.InfoData className="content-data">
        {headingTitle && <Heading content={headingTitle} />}
        <S.LeftInfo>
          {/**bg={currentItem.data} */}
          <S.ArtImage
            src={currentItem.data}
            alt="art-img"
            onClick={goToDetail}
          />
        </S.LeftInfo>

        <S.Content className="buy-together-info">
          {eventType === "EVENT_RB" ? (
            <>
              <div className="tag--event">EVENT</div>
              <S.TitleContent>{artName}</S.TitleContent>
              <S.InfoTitle className="amount-event">
                {t("HOME_PRICE")}:{" "}
                <span className="text-medium">
                  {groupSaleComming
                    ? moneyConvertLang(0)
                    : moneyConvertLang(artTargetAmount)}
                </span>
              </S.InfoTitle>
            </>
          ) : (
            <>
              <S.TitleContent>{artName}</S.TitleContent>
              {artStatus && <S.SubTitleContent>{artStatus}</S.SubTitleContent>}
              <S.InfoTitle className="progress">
                {t("DETAIL_GROUP_SALE_JOIN_PROGRESS")}{" "}
                <span className="text-medium">
                  {groupSaleComming ? 0 : artProgress}%
                </span>
              </S.InfoTitle>
              <ProgressBar percent={groupSaleComming ? 0 : artProgress ?? 0} />
              <S.InfoTitle className="amount-total">
                {t("DETAIL_GROUP_SALE_PURCHASING_PRICE")}:{" "}
                <span className="text-medium">
                  {groupSaleComming
                    ? moneyConvertLang(0)
                    : moneyConvertLang(artTotalAmount)}
                </span>
              </S.InfoTitle>
              <S.InfoTitle className="target">
                {t("DETAIL_GROUP_SALE_TARGET_PRICE")}:{" "}
                <span className="text-medium">
                  {moneyConvertLang(artTargetAmount)}
                </span>
              </S.InfoTitle>
            </>
          )}
          <S.InfoTitle className="time-remaining">
            {t("DETAIL_GROUP_SALE_REMAINING_PERIOD")}:{" "}
            <span className="text-medium">
              {artTimeRemaining}
              {t("D")}
              {days === 0 && (
                <div className="box-time">
                  <div className="time">{prefixO(hours)}</div>
                  <div className="time">{prefixO(minutes)}</div>
                  <div className="time">{prefixO(seconds)}</div>
                </div>
              )}
            </span>
          </S.InfoTitle>
          <S.ActionButton
            className="action-button"
            onClick={actionButtonEvent}
            disabled={
              actionButtonDisable ||
              (days === 0 && hours === 0 && minutes === 0 && seconds === 0)
            }
          >
            {actionButtonTitle}
          </S.ActionButton>
        </S.Content>
      </S.InfoData>
    </S.SaleTogetherContainer>
  );
}

export default SaleGroup;
