import { mainAxios } from "libs/axios";

/**get check member*/
interface PropsCheckMember {
  page?: number;
  size?: number;
}

export const checkMemberGroupPurchase = (payload: PropsCheckMember) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/esr/gp-can-register`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
  });
};

/**Create a request to see a joint purchase */
interface PropsCreateJointPurchase {
  selectionTime?: string;
  registerName?: string;
  registerPhone?: string;
  numberOfParticipants?: number;
  haveCar?: string;
  groupPurchaseIds?: number[];
}

export const createJointPurchase = (payload: PropsCreateJointPurchase) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/esr/create`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const getRegisteredTime = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/esr/registered-times`,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
