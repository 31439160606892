import styled from "styled-components";

export const TabsMenu = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;  
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    padding: 0 15px;
    >div{  
      padding: 13.5px 31.5px;
    }
  }
`;

export const Tabs = styled.div<{ isCompartment?: boolean }>`
  padding: 10px 0px;
  min-width: 60px;
  margin-right: 20px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #5c7161;
  border: none;
  border-bottom: solid 4px transparent;
  cursor: pointer;
  white-space: nowrap;
  &.active {
    font-family: "NotoSansKR-Medium";
    color: #111;
    font-weight: 500;
    border-bottom: solid 4px #ad9a6d;
    /* border-bottom: 1px solid #ad9a6d; */
    @media screen and (max-width: 768px) {
      color: #5c7161;
      font-weight: 700;
      border-radius: 30px;
      border: solid 1px #5c7161;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 100%;
      height: 28px;
      border-bottom: 3px solid #ad9a6d;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &:nth-child(1) {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 28px;
        border-bottom: 3px solid #ad9a6d;
      }
    }
  }

  &:nth-child(1) {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      height: 28px;
      border-right: ${({ isCompartment }) =>
        isCompartment ? "1px solid #dfdfdf" : "none"};
    }
  }
`;
