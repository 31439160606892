/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-21 13:51:26
 * @modify date 2022-01-21 13:51:26
 * @desc [description]
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import types from "store/actions/types";

import { UrlInternal } from "common/constants/endpoints";

import * as S from "./style";

const SearchBarHeader = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state: any) => state.searchBar);

  /** Get Search Value */
  const getSearchValue = () => {
    const v: any = document.getElementById("searchInput");
    closeSearchBar(v?.value);
    window.location.href = UrlInternal.SEARCH;
  };

  /** Close SearchBar */
  const closeSearchBar = (value?: any) => {
    dispatch({
      type: types.SEARCH_BAR,
      isOpen: false,
      searchValue: value ?? "",
    });
  };

  return (
    <S.SearchBox className={isOpen ? "open" : ""}>
      <S.SearchBoxOverlay
        className={isOpen ? "open" : ""}
        onClick={closeSearchBar}
      />
      <S.SearchBoxContainer className={isOpen ? "show" : ""}>
        <S.Content className={isOpen ? "show" : ""}>
          <S.SearchInput
            id="searchInput"
            placeholder="검색어를 입력해주세요..."
          />
          <S.SearchButton
            type="text"
            shape="round"
            icon={<span className="ti ti-search"></span>}
            onClick={getSearchValue}
          />
        </S.Content>
      </S.SearchBoxContainer>
    </S.SearchBox>
  );
};

export default SearchBarHeader;
