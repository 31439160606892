import { mainAxios } from "libs/axios";

/**create request verify Props type */
interface createRequestVerify {
  requestType?: string;
}

export const createRequestVerifyApi = (payload: createRequestVerify) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/nice-api/create-request-verify`,
    payload: payload,
    notError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**get data by token Props type */
interface getDataByToken {
  tokenVersionId?: string;
}

export const dataByTokenApi = (payload: getDataByToken) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/nice-api/get-data-by-token`,
    payload: payload,
    notError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const createRequestVerifyChangePhoneApi = (
  payload: createRequestVerify
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/nice-api/create-request-verify`,
    payload: payload,
    requiresToken: true,
    notError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
