import Loading from "containers/Loading";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as S from "./style";

function FormNiceApi() {
  const history = useHistory();
  const { state }: any = history.location;

  console.log("113", state);

  useEffect(() => {
    const footerDom = document.getElementById("footer");
    footerDom?.classList.add("footer-none");
    if (state) {
      const form: any = document.getElementById("form-nice");
      form?.submit();
    }
  }, []);

  return (
    <>
      <Loading />
      <S.Form
        name="form-nice"
        id="form-nice"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
        method="post"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input
          type="text"
          id="enc_data"
          name="enc_data"
          value={state?.dataEncrypted}
        />
        <input
          type="text"
          id="integrity_value"
          name="integrity_value"
          value={state?.integrityValue}
        />
        <input
          type="text"
          id="token_version_id"
          name="token_version_id"
          value={state?.tokenVersionId}
        />
      </S.Form>
    </>
  );
}

export default FormNiceApi;
