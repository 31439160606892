import { Form, Input } from "antd";
import { PopupMyPage } from "components";
import { PopupProps } from "components/MypagePopup";
import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import rootAction from "store/actions";
import * as S from "./style";

interface Props extends PopupProps { }

function ModalReStock(props: Props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { dataUser } = useSelector((state: any) => state.dataUser);

  useEffect(() => {
    dispatch(rootAction.getUserAction.getDataUser());
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      phone: dataUser?.phoneNumber,
    });
  }, [dataUser]);

  return (
    <PopupMyPage
      content={
        <S.WrapperForm>
          <h2>입고요청 신청서</h2>
          <Form size="large" layout="vertical" form={form}>
            <Form.Item label="전화번호" name="phone">
              {/* <Input
                placeholder="전화번호를 입력해주세요"
                id="stockPhone"
                type="number"
              /> */}
              <NumberFormat
                format="###-####-####"
                name="personalTaxCode"
                placeholder="전화번호를 입력해주세요"
                id="stockPhone"
              />
            </Form.Item>
            <Form.Item label="입고요청수량" name="quantity">
              <NumberFormat
                placeholder="입고요청수량을 입력해주세요."
                id="stockQuantity"
                maxLength={8}
              />
            </Form.Item>
          </Form>
        </S.WrapperForm>
      }
      onlyOk={false}
      mypage={true}
      {...props}
    />
  );
}

export default ModalReStock;
