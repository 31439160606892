import React from "react";
import * as S from "./style";

interface Props {
  percent?: number;
}

function ProgressBar({ percent }: Props): React.ReactElement {
  return (
    <S.ProgressBar
      className="percent-progress"
      strokeColor={"#5c7161"}
      showInfo={false}
      percent={percent}
      trailColor={"#f2f2f2"}
    />
  );
}

export default ProgressBar;
