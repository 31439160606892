import styled from "styled-components";

export const Container = styled.div`
    
`;

export const Content = styled.span`
   font-family: 'NotoSansKR-Light';
   font-size: 45px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 1.13px;
  text-align: left;
  color: #111;
  text-transform: uppercase;
  @media screen and (max-width: 425px) {
    font-size: 30px;
  }
`;

