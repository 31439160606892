/**
 * @author [Peter]
 * @email [hoangvanlam9988@mail.com]
 * @create date 2022-04-18 15:45:40
 * @modify date 2022-04-18 15:45:40
 * @desc [description]
 */

import React from "react";
import moment from "moment";

/** Utils */
import moneyConvert from "utils/moneyConvert";

/** Local Style */
import * as S from "./style";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  noNumber: any;
  data: any;
  gotToDetail?: () => void;
}

const ArtTop10CardItem = ({ noNumber, data, gotToDetail }: Props) => {
  const { t } = useTranslation();
  return (
    <S.CardContent onClick={gotToDetail}>
      <div className="card-head">
        <div className="card-head__num">NO.{noNumber ?? 1}</div>
        <div className="card-head__title">
          <span>{data?.authorName}</span>
          {/* <span>{data?.productName}</span> */}
        </div>
      </div>
      <div className="card-content">
        <div className="card-content__author">{data?.productName}</div>
        {/**Untitled, 1999 */}
        <div className="card-content__saleprice">
          {t("TOP10_BID_PRICE")}:{moneyConvertLang(data?.price)}
        </div>
        <div className="card-content__more">
          <span>
            {t("TOP10_DATE")}:{" "}
            {data?.tradeDay
              ? moment(data?.tradeDay ?? "").format("YYYY.MM.DD")
              : ""}
          </span>
        </div>
        <div className="card-content__place">
          {t("TOP10_HOUSE")}:{" "}
          {data?.exhibitionDestination?.length > 35
            ? `${data?.exhibitionDestination.substring(0, 35)}...`
            : data?.exhibitionDestination}
        </div>
      </div>
    </S.CardContent>
  );
};

export default ArtTop10CardItem;
