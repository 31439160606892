import { Progress } from "antd";
import styled from "styled-components";

export const ProgressBar = styled(Progress)`
  .ant-progress-inner {
    border-radius: unset;
    width: 100%;
    height: 20px;

    .ant-progress-bg {
      height: 20px !important;
      border-radius: unset;
    }
  }
`;
