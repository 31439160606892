import { Modal } from "antd";
import styled from "styled-components";

export const Modals = styled(Modal)<{
  widthProps: string;
  heightProps: string;
  showOnPc: number;
  showOnMobile: number;
}>`
  width: ${({ widthProps }) =>
    widthProps ? `${widthProps}px !important` : "400px"};
  height: ${({ heightProps }) =>
    heightProps ? `${heightProps}px !important` : "200px"};
  display: ${({ showOnPc }) => (showOnPc ? "block" : "none")};
  @media (max-width: 768px) {
    display: ${({ showOnMobile }) => (showOnMobile ? "block" : "none")};
  }
  .ant-modal-body {
    /* border: solid 5px #ad9a6d; */
    position: relative;
    padding: 65px 35px 76px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
  top: 150px;
`;

export const Container = styled.div`
  h2 {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ad9a6d;
    margin: 0;
  }
  button {
    height: 45px;
    border: none;
    text-align: center;
    font-family: "NotoSansKR-Medium";
    font-size: 14px;
    font-weight: 500;
  }
`;

export const WrapContent = styled.div`
  padding: 30px 20px 50px 20px;
`;

export const Image = styled.img`
  width: 100%;
  height: 30px;
  object-fit: cover;
`;

export const ButtonConfirm = styled.button`
  background-color: #607664;
  color: #fff;
  flex: 1;
`;

export const ButtonCancel = styled.button`
  border: 1px solid #607664 !important;
  background-color: #fff;
  color: #607664;
  width: 150px;
  @media (max-width: 425px) {
    width: 100px;
  }
  @media (max-width: 360px) {
    width: 85px;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  gap: 20px;
`;

export const Title = styled.div`
  color: #607664;
  letter-spacing: 0.8px;
  font-family: "NotoSansKR-Medium";
  font-size: 32px;
  text-align: center;
`;

export const Content = styled.div`
  color: #111;
  padding: 21px 0 35px;
  letter-spacing: 0.4px;
  font-family: "NotoSansKR-Light";
  font-size: 16px;
  text-align: center;
  cursor: pointer;
`;
