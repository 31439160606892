import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { PaginationProps } from "antd";
import React from "react";
import { PaginationStyled } from "./style";
const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <PaginationStyled
      itemRender={(_, type, origin) => {
        if (type === "prev") {
          return <ArrowLeftOutlined />;
        }
        if (type === "next") {
          return <ArrowRightOutlined />;
        }
        return origin;
      }}
      showSizeChanger={false}
      defaultPageSize={10}
      defaultCurrent={1}
      {...props}
    />
  );
};

export default Pagination;
