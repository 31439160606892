import { getUserApi } from "apis";
import { getLocalStorage } from "utils/localStorage";
import { GETME } from "utils/localStorage/type";
import types from "./types";

const getDataUser = () => async (dispatch: any) => {
  try {
    let acToken = getLocalStorage(GETME, 0);

    if (acToken) {
      const { data }: any = await getUserApi();
      dispatch({
        type: types.GET_DATA_USER,
        dataUser: data,
      });
    }
  } catch (error) {}
};
export default { getDataUser };
