/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-04-19 11:04:25
 * @modify date 2022-04-19 11:04:25
 * @desc [description]
 */

import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./style";

interface Props {
  content?: string;
  linkTo?: any;
}

const Heading = (props: Props) => {
  const history = useHistory();
  const { content, linkTo } = props;
  return (
    <S.Container className="heading-component" onClick={() => linkTo ? history.push(linkTo) : {}}>
      <S.Line></S.Line>
      <S.Content>{content}</S.Content>
    </S.Container>
  );
};

export default Heading;
