/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:33:01
 * @modify date 2022-01-19 14:33:01
 * @desc [description]
 */

import { Button } from "antd";
import styled from "styled-components";

interface ItemProps {
  bg?: any;
}

export const SaleTogetherContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContent = styled.span`
  width: 100%;
  font-family: "Montserrat-Medium";
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #5c7161;
  text-overflow: ellipsis;
  overflow: auto;
  word-break: break-all;
`;

export const LeftInfo = styled.div`
  width: 564.9px;
  height: 590px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 30px;

  @media screen and (max-width: 1440px) {
    max-width: 50%;
    height: auto;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
  }
`;

export const ArtImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.3s;
  transition-timing-function: ease;
`;

export const InfoData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 35px 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @supports not ((gap: "35px 0") and (display: flex)) {
      gap: 0px;
      .sale-together-info {
        margin-top: 35px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 12px 0px;
  padding-left: 152.1px;

  .text-price {
    /* padding-right: 15px; */
    display: inline-block;
    width: 120px;
  }

  .fee-transport {
    margin-top: 15px;
  }

  .fee-transport-modified {
    margin-bottom: 15px;
  }

  &.sale-together-info {
    padding-left: 52.1px;
    flex: 1;
  }

  .ant-progress-inner {
    width: 100%;
  }
  @media screen and (max-width: 1440px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    /* padding-left: 0px; */
    @media screen and (max-width: 768px) {
      padding-left: 0;
    }

    &.sale-together-info {
      /* padding-left: 0px; */
      @media screen and (max-width: 768px) {
        padding-left: 0;
      }
    }
  }

  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

export const SubTitleContent = styled.span`
  font-size: 18px;
  font-family: "Montserrat-Light";
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
  color: #ad9a6d;
`;

export const InfoTitle = styled.span`
  font-family: "Montserrat-Light";
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.55px;
  text-align: left;
  color: #5c7161;

  &.amount {
    margin: 0px 0px 24px 0px;
    &.orderHaveSlot {
      margin: 32px 0px 24px 0px;
    }
  }

  &.quantity {
    margin: 0px 0px 70px 0px;
    gap: 27px;

    @media (max-width: 768px) {
      @supports not ((gap: "27px") and (display: flex)) {
        gap: 0px;
        .text-light {
          margin-right: 27px;
        }
      }
    }
  }

  &.old-price {
    margin: 24px 0px;
  }

  &.remanning-number {
    margin: 17px 0px 0px 0px;
  }

  &.flex-layout {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &.gap-17 {
    gap: 17px;
  }

  &.gap-9 {
    gap: 9px;
  }

  &.mrb-14 {
    margin-bottom: 14px;
  }

  &.mrb-24 {
    margin-bottom: 24px;
  }

  .text-medium {
    font-weight: 600;
    font-family: "Montserrat-Bold";
  }

  .text-light {
    font-family: "Montserrat-Light";
  }

  .text-decoration {
    text-decoration: line-through;
  }

  .text-percent {
    font-family: "Montserrat-Regular";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #de8808;
  }

  &.processing-bw {
    margin: 24px 0px 14px 0px;
  }

  &.processing-remanning {
    margin: 0px 0px 50px 0px;
  }
`;

export const StockAmountInput = styled.input`
  width: 330px;
  height: 40px;
  outline: none;
  font-size: 18px;
  font-family: "NotoSansKR-Light";
  margin: 20px 0px;
  padding: 0px 10px;
  border: 1px solid gray;

  &:active,
  :focus {
    outline: none;
  }

  &::placeholder {
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.13px;
    text-align: left;
    color: #858585;
  }

  @media (max-width: 540px) {
    width: 100%;
  }
`;

export const ActionButton = styled(Button)`
  width: 564px;
  /* max-width: 564px; */
  height: 65px;
  background-color: #5c7161;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  &.type-restock {
    margin-top: 70px;
    @media (max-width: 768px) {
      margin-top: 41px;
    }
  }

  &:hover,
  :active,
  :focus {
    background-color: #5c7161;
    border-color: #5c7161;
    color: #ffffff;
  }

  &[disabled] {
    color: #ffffff;
    background-color: #ebebeb;
  }

  @media (max-width: 1366px) {
    width: 100%;
  }

  @media (max-width: 540px) {
    width: 100%;
  }
`;

export const ListImage = styled.div`
  max-width: 186.3px;
  overflow-wrap: unset;
  /* overflow-y: auto; */
  margin-right: 30.7px;
  display: flex;
  flex-direction: column;
  gap: 15.5px;

  &.ui-768 {
    display: none;
    margin-bottom: 69.1px;
    margin-top: 0px;

    @media screen and (max-width: 768px) {
      display: unset;
      height: auto;
    }
  }

  @media screen and (max-width: 1024px) {
    /* display: none; */
    max-width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    overflow: auto;
    margin-right: 0;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  div {
    /* display: flex;
    flex-direction: column;
    gap: 15.5px;
    margin: auto;
    max-width: 400px;
    height: auto;
    flex-wrap: wrap;
    padding: 10px; */
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  }

  &.mrt-80 {
    margin-top: 80px;
  }
`;

export const ItemtImage = styled.img`
  width: 100%;
  /* height: 250px; */
  object-fit: contain;
  cursor: pointer;

  &.active {
    /* border: 1px solid black; */
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  }

  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
`;
