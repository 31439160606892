/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:23:06
 * @modify date 2022-01-19 14:23:06
 * @desc [description]
 */

import React, { useEffect, useState } from "react"; //lazy
// import { useHistory } from "react-router-dom";

/** Components */
import { ErrorSpan, Heading, ProgressBar, Select } from "components";

/** Untils */
import moneyConvert from "utils/moneyConvert";

/** Style Local */
import { Empty } from "antd";
import * as S from "./style";
import { useCountdown } from "hooks/useCountdown";
import { useHistory } from "react-router-dom";
import { UrlInternal } from "common/constants/endpoints";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  headingTitle?: string;
  artName?: string;
  artStatus?: string;
  artProgress?: number;
  artTotalAmount?: number;
  artTargetAmount?: number;
  artTimeRemaining?: number;
  actionButtonDisable?: boolean;
  actionButtonTitle?: string;
  actionButtonEvent?: () => void;
  listSubImage?: any;
  goToDetail?: () => void;
  notButton?: boolean;
  errorContent?: string;
  // statusSaleEnd?: boolean;
  maxStock?: number;
  maxUnit?: number;
  getQuantity?: any;
  pageSaleEnd?: boolean;
  waitingOrder?: boolean;
  eventType?: string;
  eventEndAt?: string;
  endAt?: string;
  artTimeRemainingEvent?: number;
}

function SaleGroup({
  headingTitle,
  artName,
  artStatus,
  artProgress = 0,
  artTotalAmount = 0,
  artTargetAmount = 0,
  artTimeRemaining = 0,
  actionButtonDisable = false,
  actionButtonTitle,
  actionButtonEvent,
  listSubImage = [],
  goToDetail,
  notButton,
  errorContent,
  // statusSaleEnd,
  maxStock = 0,
  getQuantity,
  maxUnit,
  pageSaleEnd,
  waitingOrder,
  eventType,
  eventEndAt,
  endAt,
  artTimeRemainingEvent = 0,
}: Props) {
  const history = useHistory();
  const [currentItem, setCurrentItem] = useState<any>({
    id: 0,
    data: listSubImage[0] ?? "",
  });
  const { t } = useTranslation();

  const [days, hours, minutes, seconds] = useCountdown(
    new Date(eventEndAt ? eventEndAt : endAt ?? new Date()).getTime(),
    eventEndAt ? artTimeRemainingEvent : artTimeRemaining
  );

  useEffect(() => {
    setCurrentItem({
      id: 0,
      data: listSubImage[0] ?? "",
    });
  }, [listSubImage]);

  const prefixO = (dataNumber: number) => {
    return dataNumber < 10 ? `0${dataNumber}` : dataNumber;
  };

  useEffect(() => {
    if (
      days <= 0 &&
      hours <= 0 &&
      minutes <= 0 &&
      seconds <= 0 &&
      eventType !== "EVENT_RB" &&
      !pageSaleEnd
    ) {
      history.push(UrlInternal.GROUP_SALE_END);
    }
  }, [days, hours, minutes, seconds, eventType, pageSaleEnd]);

  return (
    <S.SaleTogetherContainer className="sale-group">
      <S.ListImage>
        {/* <div> */}
        {listSubImage?.map((v: any, i: number) => (
          <S.ItemtImage
            className={currentItem.id === i ? "active" : ""}
            key={i}
            src={v}
            alt="image-item"
            onClick={() => setCurrentItem({ id: i, data: v })}
          />
        ))}
        {/* </div> */}
      </S.ListImage>
      <S.InfoData className="content-data">
        <S.LeftInfo>
          {/**bg={currentItem.data} */}
          {currentItem.data ? (
            <S.ArtImage
              src={currentItem.data}
              alt="art-img"
              onClick={goToDetail}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Image"
            />
          )}
        </S.LeftInfo>
        {eventType === "EVENT_RB" ? (
          <S.Content className="sale-together-info">
            <S.ContainerEvent>
              <div className="event--tag">EVENT</div>
              <div className="event--title">{artName}</div>
              <div className="event--price">
                {t("HOME_PRICE")}:{" "}
                <span>{moneyConvertLang(artTargetAmount)}</span>
              </div>
              <div className="event--price event--price__spacing">
                {t("DETAIL_GROUP_SALE_REMAINING_PERIOD")}:{" "}
                <span>
                  {artTimeRemainingEvent}
                  {t("D")}
                  {days === 0 && (
                    <div className="box-time">
                      <div className="time">{prefixO(hours)}</div>
                      <div className="time">{prefixO(minutes)}</div>
                      <div className="time">{prefixO(seconds)}</div>
                    </div>
                  )}
                </span>
              </div>
              {!notButton && (
                <button
                  className="event--button"
                  onClick={actionButtonEvent}
                  disabled={
                    actionButtonDisable ||
                    (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0)
                  }
                >
                  {t("BTN_APPLY")}
                </button>
              )}
            </S.ContainerEvent>
          </S.Content>
        ) : (
          <S.Content className="sale-together-info">
            <S.Head>
              {headingTitle && <Heading content={headingTitle} />}
              <S.TitleContent>{artName}</S.TitleContent>
            </S.Head>
            {artStatus && <S.SubTitleContent>{artStatus}</S.SubTitleContent>}
            <S.InfoTitle className="progress">
              {t("DETAIL_GROUP_SALE_JOIN_PROGRESS")}:{" "}
              <span className="text-medium">{artProgress}%</span>
            </S.InfoTitle>
            <ProgressBar percent={artProgress ?? 0} />
            <S.InfoTitle className="amount-total">
              {t("PURCHASING_PRICE")}:{" "}
              <span className="text-medium">
                {moneyConvertLang(artTotalAmount)}
              </span>
            </S.InfoTitle>
            <S.InfoTitle className={`target ${pageSaleEnd ? "saleEnd" : ""}`}>
              {t("HOME_PRICE")}:{" "}
              <span className="text-medium">
                {moneyConvertLang(artTargetAmount)}
              </span>
            </S.InfoTitle>
            {!pageSaleEnd && (
              <>
                <S.InfoTitle
                  className={`time-remaining ${errorContent ? "mrb-50" : ""}`}
                >
                  {t("DETAIL_GROUP_SALE_REMAINING_PERIOD")}:{" "}
                  <span className="text-medium">
                    {artTimeRemaining}
                    {t("D")}
                    {days === 0 && (
                      <div className="box-time">
                        <div className="time">{prefixO(hours)}</div>
                        <div className="time">{prefixO(minutes)}</div>
                        <div className="time">{prefixO(seconds)}</div>
                      </div>
                    )}
                  </span>
                </S.InfoTitle>

                <S.InfoTitle className="quantity flex-layout modified">
                  <span className="text-light quantity">{t("QTY")}</span>
                  <S.WpSelect>
                    <Select
                      maxStock={waitingOrder ? maxUnit : maxStock}
                      getSelectValue={getQuantity}
                      valueDefault={1}
                      maxUnit={maxUnit}
                    />
                  </S.WpSelect>
                </S.InfoTitle>
              </>
            )}

            {!notButton && (
              <S.ActionButton
                className="action-button"
                onClick={actionButtonEvent}
                disabled={
                  actionButtonDisable ||
                  (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0)
                }
              >
                {actionButtonTitle}
              </S.ActionButton>
            )}
            {errorContent && errorContent != "" && (
              <ErrorSpan content={errorContent} />
            )}
          </S.Content>
        )}
      </S.InfoData>
    </S.SaleTogetherContainer>
  );
}

export default SaleGroup;
