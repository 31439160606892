import styled from "styled-components";

export const Content = styled.div`
  text-align: center;
  font-size: 28px;
  font-family: "NotoSansKR-Regular";
  line-height: 1.46;
  letter-spacing: 0.7px;
  text-align: center;
  color: #5d7160;
  margin: 0;
`;
