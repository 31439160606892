import { getPopupApi } from "apis";
import { PopupAdmin } from "components";
import React, { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";

function PopupHandleAdmin() {
  const [data, setData] = useState<any>();
  const pathname = window.location.pathname;

  useEffect(() => {
    (async function getDataPopup() {
      try {
        const { data }: any = await getPopupApi();
        if (data) {
          setData(data.datas);
        } else {
          setData([]);
        }
      } catch (error) {
        setData([]);
      }
    })();
  }, []);

  const renderItem = (data: any, pathname: string) => {
    const oldCookies: any = Cookies.get("POPUP");
    const arrCookies = oldCookies && oldCookies.split`,`.map((x: string) => +x);
    if (oldCookies) {
      return data?.filter((item: any) => {
        const routes = item.routes.split(" ");
        return (
          !arrCookies.includes(item.id) &&
          (routes.includes(pathname) || item.routes === "/*")
        );
      });
    } else {
      return data?.filter((item: any) => {
        const routes = item.routes.split(" ");
        return routes.includes(pathname) || item.routes === "/*";
      });
    }
  };

  // const renderTest = useMemo(() => {
  //   return renderItem(data, pathname);
  // }, [data, pathname]);

  return (
    <>
      {renderItem(data, pathname)?.map((data: any, index: number) => (
        <PopupAdmin data={data} key={index} />
      ))}
    </>
  );
}

export default PopupHandleAdmin;
