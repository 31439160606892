import { mainAxios } from "libs/axios";

/**Create Faq history Props type */
interface faqProps {
  page?: string;
  size?: string;
  query?: string;
  startTime?: string;
  endTime?: string;
  categoryCode?: string;
}

export const getFaqApi = (payload: faqProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/faq/get-all`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**get all category faq */
interface faqCategoryProps {
  page?: string;
  size?: string;
  query?: string;
}

export const getCategoryFaqApi = (payload: faqCategoryProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/faq/get-all-category`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
