/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2021-07-19 09:56:23
 * @modify date 2021-07-19 09:56:23
 * @desc [description]
 */

export const GETME: any = "ART_GET_ME"; // user info
export const AVATAR: any = "AVATAR";
export const TOKEN: any = "TOKEN";
export const LANG: string = "LANG";
