import React from "react";
import Lottie, { Options } from "react-lottie";
import CheckJson from "assets/json_img/check.json";

import * as S from "./style";

function CheckLottie(): React.ReactElement {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: CheckJson,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };
  return (
    <S.Container>
      {/* <Spin size="large" /> */}
      <Lottie options={defaultOptions} height={210} width={210} />
    </S.Container>
  );
}

export default CheckLottie;
