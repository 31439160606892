import img from "assets/images/_popup/popup_head.png";
import Cookies from "js-cookie";
import React, { useState } from "react";
import * as S from "./style";

export interface PopupProps {
  data?: any;
}

const PopupAdmin: React.FC<PopupProps> = ({ data }) => {
  const [visible, setVisible] = useState<boolean>(true);

  const handleCloseOneDay = (id: number) => {
    const oldCookies: any = Cookies.get("POPUP");
    if (oldCookies) {
      Cookies.set("POPUP", `${[oldCookies, id]}`, {
        expires: 1,
      });
    } else {
      Cookies.set("POPUP", `${[id]}`, {
        expires: 1,
      });
    }
    setVisible(false);
  };

  // const handleCloseOneDay = (id: any) => {
  //   id = id + "";
  //   const oldCookies: any = Cookies.get("POPUP");
  //   if (oldCookies) {
  //     Cookies.set("POPUP", oldCookies, {
  //       expires: 1,
  //     });
  //   } else {
  //     Cookies.set("POPUP", id, {
  //       expires: 1,
  //     });
  //   }
  //   setVisible(false);
  // };

  return (
    <>
      <S.Modals
        visible={visible}
        closable={false}
        footer={null}
        onCancel={() => setVisible(false)}
        widthProps={data?.width}
        heightProps={data?.height}
        showOnPc={data?.showOnPc}
        showOnMobile={data?.showOnMobile}
        bodyStyle={{ padding: 0 }}
      >
        <S.Container>
          {/* <S.Image src={img} alt="head-popup" /> */}
          <S.WrapContent>
            {/* <S.Title>{data?.title}</S.Title> */}
            <S.Content
              dangerouslySetInnerHTML={{ __html: data?.contentHtml }}
              onClick={() => {
                if (data?.redirectUrl) {
                  window.open(data?.redirectUrl, "_blank");
                }
              }}
            ></S.Content>
            <S.WrapperButton>
              <S.ButtonConfirm onClick={() => handleCloseOneDay(data?.id)}>
                24시간 동안 다시 열람하지 않습니다
              </S.ButtonConfirm>
              <S.ButtonCancel onClick={() => setVisible(false)}>
                닫기
              </S.ButtonCancel>
            </S.WrapperButton>
          </S.WrapContent>
        </S.Container>
      </S.Modals>
    </>
  );
};

export default PopupAdmin;
