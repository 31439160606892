import { Select } from "antd";
import styled from "styled-components";

export const Selects = styled(Select)`
  /* width: calc(100% - 50px); */
  /* min-width: 160px; */

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #e5e5e5 !important;
    box-shadow: unset !important;
    padding: 0 !important;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    font-weight: 600;
    color: #5d7160;
    min-width: 80px;
    margin-right: 18px;
  }
  svg path {
    fill: #5d7160;
  }
`;
