import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  img {
    width: 35.4px;
    height: 35.4px;
    @media (max-width: 360px) {
      width: 29px;
      height: 29.4px;
    }
  }
`;

export const Line = styled.div`
  border-bottom: solid 1px #5c7161;
  width: 48px;
  height: 1px;
  margin-left: 7.5px;
`;

export const Content = styled.span`
  font-family: "DMSerifDisplay-Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #5c7161;
  text-transform: uppercase;
  margin-left: 7.5px;

  @media (max-width: 360px) {
    letter-spacing: 0.33px;
    font-size: 14px;
  }
`;
