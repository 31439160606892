/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-24 15:52:38
 * @modify date 2022-01-24 15:52:38
 * @desc [description]
 */

import styled from "styled-components";

export const CardContent = styled.div`
  width: 617px;
  min-height: 482px;
  border-radius: 8px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.27);
  background-color: #5d7160;

  .card-head {
    height: 91px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 28px;
    padding-right: 33px;

    &__title {
      font-family: "Montserrat-Medium";
      font-size: 35px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: -0.53px;
      text-align: left;
      color: #fff;
      text-transform: uppercase;
      max-height: 40px;
      white-space: nowrap;
      max-width: calc(100% - 33px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__more {
      width: 33px;
      height: 33px;
      cursor: pointer;

      svg {
        width: 33px;
        height: 33px;
      }
    }
  }

  .card-date {
    width: 617px;
    height: 43px;
    padding: 9px 33px 9px 29px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 33px 0px 29px;

    .date,
    .vol {
      font-family: "Montserrat-Bold";
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.32px;
      text-align: left;
      color: #5d7160;
    }
  }

  .card-info {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 39.5px 33px 43px 29px;
    font-stretch: normal;
    font-style: normal;
    overflow: hidden auto;

    &__title {
      word-break: keep-all;
      min-height: 45px;
      display: flex;
      align-items: center;
      border-top: solid 1px #fff;
      border-bottom: solid 1px #fff;
      font-family: "Montserrat-Medium";
      font-size: 25px;
      font-weight: 500;
      letter-spacing: -1.75px;
      text-align: left;
      /* display: -webkit-box; */
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 2px 0;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__content {
      display: flex;
      word-break: keep-all;
      padding: 13.5px 0px 29.5px 0px;
      font-family: "Montserrat-Medium";
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.32px;
      text-align: left;
      /* display: -webkit-box; */
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100%;

    .card-head {
      &__title {
        font-size: 26px;
        letter-spacing: -0.39px;
      }
    }

    .card-date {
      width: 100%;
    }

    .card-info {
      &__title {
        min-height: 48px;
        font-size: 16px;
        letter-spacing: -1.12px;
      }
      &__content {
        font-size: 16px;
        letter-spacing: -0.24px;
        padding: 14.5px 0px 21.5px 0px;
      }
    }
  }

  @media (max-width: 425px) {
    width: 330px;
  }
`;
