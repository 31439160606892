import { mainAxios } from "libs/axios";

/** Get Banner Props Type */
interface BannerProps {
  page?: any;
  size?: any;
  query?: any;
  startTime?: any;
  endTime?: any;
}

export const getBanner = (payload: BannerProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/banner/get-all`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** Get Purchase Props Type */
interface PurchaseProps {
  querySearch?: any;
  typeSearch?: string;
  page?: any;
  size?: any;
}

export const getPurchase = (params: PurchaseProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/find`,
    params: params,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const getPurchaseHomeApi = (params: PurchaseProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/find`,
    params: params,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** Get Top10 Artists Type */
interface Top10ArtistsListProps {
  size?: number;
  page?: number;
}

export const getTop10ArtistsList = (params: Top10ArtistsListProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/top-artist/get-all`,
    params: params,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** Get NewSletter Type */
interface NewSletterProps {
  page?: any;
  size?: any;
  query?: any;
  startTime?: any;
  endTime?: any;
  categoryCode?: "NEWS";
  titleSearch?: any;
  authorSearch?: any;
  contentSearch?: any;
  categories?: any;
  hashtag?: any;
}

export const getNewSletterList = (payload: NewSletterProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/article/get-all`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const getTitleTop10ArtistsList = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/top-artist/title`,
    notError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
