import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  max-width: 1398px;
  width: 100%;
  height: 111.5px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  border-bottom: solid 1px #9bb29f;
  display: flex;
  align-items: center;
  gap: 50px;
  overflow: auto;
  height: 114px;
  /* margin: 0 15px; */
  @media (max-width: 1024px) {
    gap: 30px;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 767px) {
    gap: 10px;
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  font-family: "Montserrat-Light";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.29;
  color: #5c7161;
  cursor: pointer;
  white-space: nowrap;
  /* overflow: hidden; */
  padding: 0px 30px;
  ${(props) => {
    if (props.active) {
      return css`
        border-radius: 23px;
        border: solid 1px #5c7161;
        background-color: #fff;
        /* min-width: 123px; */
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-family: "Montserrat-Bold";
        font-weight: bold;
        padding: 0px 30px;
        /* @media screen and (max-width: 768px) {
          padding: 0;
        } */
      `;
    }
  }}
`;
