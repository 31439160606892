/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-24 11:53:41
 * @modify date 2022-01-24 11:53:41
 * @desc [description]
 */

import React from "react";
import { Empty, Image } from "antd";
import { useHistory } from "react-router";
/** Utils */
import moneyConvert from "utils/moneyConvert";

/** Images */
import art_market_slider_1 from "assets/images/_art_market_slider/art_market_slider_1.png";

/** Local Style */
import * as S from "./style";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  image: string;
  artName?: string;
  title: string;
  priceSale: number;
  price: number;
  className?: string;
  isFavorite?: boolean;
  linkToDetail?: () => void;
  isOuStock?: boolean;
  saleOffPercent?: number;
}

const ArtCardItemFavorite = ({
  image = art_market_slider_1,
  artName,
  title,
  priceSale,
  price,
  className,
  // isFavorite,
  linkToDetail,
  isOuStock,
  saleOffPercent,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <S.CardContent className={`art-card-item ${className}`}>
      <div className="container">
        <div className="image-card" onClick={linkToDetail}>
          {image ? (
            <Image src={image} alt={"art"} preview={false} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Image"
            />
          )}
        </div>
        {/* <S.CardMeta> */}
        <S.CardArtName>{artName}</S.CardArtName>
        <S.CardTitle>{title}</S.CardTitle>
        <S.CardPrice>
          {isOuStock ? (
            <>{t("SOLD_OUT")}</>
          ) : (
            <>
              {priceSale !== price && (
                <>
                  <span className="old-price">{moneyConvertLang(price)}</span>
                </>
              )}
              <span className={priceSale !== price ? "current-price" : ""}>
                {moneyConvertLang(priceSale)}
              </span>
              {priceSale !== price && (
                <span className="sale-person">-{saleOffPercent}%</span>
              )}
            </>
          )}
        </S.CardPrice>
        {/* </S.CardMeta> */}
      </div>
    </S.CardContent>
  );
};

export default ArtCardItemFavorite;
