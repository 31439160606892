import { Modal } from "antd";
import React, { ReactNode } from "react";
import * as S from "./style";
export interface PopupProps {
  visible: boolean;
  setVisible: () => void;
  content?: ReactNode;
  onlyOk?: boolean;
  okEvent?: () => void;
  isOtherPage?: boolean;
  customClassName?: string;
  isNotMarginTop?: boolean;
  btnMin?: boolean;
  mypage?: boolean;
  noBtn?: boolean;
}

const Popup: React.FC<PopupProps> = ({
  visible,
  setVisible,
  content,
  onlyOk,
  okEvent,
  isOtherPage,
  customClassName,
  isNotMarginTop = false,
  btnMin,
  mypage,
  noBtn,
}) => {
  return (
    <>
      <S.ModalPopup
        visible={visible}
        // width={400}
        width={"max-content"}
        top={isOtherPage ? "100px" : ""}
        closable={false}
        footer={null}
        onCancel={() => setVisible()}
      >
        <S.Container className={isOtherPage ? `otherpage-popup-container ${customClassName}` : ""}>
          {content}
          {!noBtn && (
            <S.WrapperButton
              isNotMarginTop={isNotMarginTop}
              mypage={mypage}
              onlyOk={onlyOk}
              className={btnMin ? "min" : ""}
            >
              {!onlyOk ? (
                <>
                  <S.ButtonCancel
                    className={isOtherPage ? "otherpage-popup-cancel" : ""}
                    onClick={() => setVisible()}
                  >
                    취소
                  </S.ButtonCancel>
                  <S.ButtonConfirm
                    id="btn"
                    className="mrl-20"
                    onClick={okEvent}
                  >
                    확인
                  </S.ButtonConfirm>
                </>
              ) : (
                <S.ButtonConfirm
                  // className={btnMin ? "min" : ""}
                  onClick={okEvent}
                >
                  확인
                </S.ButtonConfirm>
              )}
            </S.WrapperButton>
          )}
        </S.Container>
      </S.ModalPopup>
    </>
  );
};

export default Popup;
