import styled from "styled-components";
import Close from "assets/icons/close.svg";

export const ModalClose = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: url(${Close}), auto;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10500;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100;
  padding: 57px 39px 48px;
  margin: 25vh auto 0;
  max-width: 408px;
  min-height: 300px;
  vertical-align: middle;
  position: relative;

  p {
    text-align: center;
  }
  @media (max-width: 360px) {
    max-width: 330px;
  }
  @media screen and (max-width: 280px) {
    width: 100%;
    padding: 17px 0px 35px 0px;
    border-radius: 0px;
    /* max-width: 250px; */
  }
`;

export const Img = styled.img`
  position: absolute;
  top: -6.5%;
  left: -13%;
`;

export const WrapperTabs = styled.div`
  display: flex;
`;

export const WrapperForm = styled.div`
  margin: 30px 0 60px;
  .ant-form-item {
    position: relative;
    margin-bottom: 31px;
    &:last-child {
      margin-top: 4px;
      margin-bottom: 0;
    }
    .ant-form-item-explain {
      position: absolute;
      bottom: -28px;
      left: 0;
    }
    .ant-form-item-control-input {
      max-height: 50px;
      .ant-input {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.4px;
        color: #5d7160;
        padding: 13px 20px;
        line-height: 22px;
      }
      .ant-btn-primary {
        background-color: #5d7160;
        height: 45px;
        width: 100%;
        border: none;
        font-family: "Montserrat-Medium";
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
    .ant-form-item-label {
      .ant-form-item-required {
        color: #5d7160;
        line-height: 1.19;
        letter-spacing: 0.4px;
        font-family: "Montserrat-Medium";
        font-size: 16px;
        font-weight: 500;
        &:before {
          content: "" !important;
          margin: 0 !important;
        }
      }
    }
  }
`;

export const Note = styled.div`
  font-family: "NotoSansKR-Regular";
  font-size: 13px;
  text-align: center;
  color: #bcbcbc;
  span {
    color: #5d7160;
  }
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  path {
    fill: #111;
  }
`;
