/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-04-15 17:16:29
 * @modify date 2022-04-15 17:16:29
 * @desc [description]
 */


import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
    percentage: number;
    content: any;
    color: any
}

const CircularProgress = ({ percentage, content, color }: Props) => {
    return (
        <CircularProgressbarWithChildren value={percentage}
            styles={buildStyles({
                textColor: color,
                pathColor: color,
            })}>
            {content}
        </CircularProgressbarWithChildren>
    )
}

export default CircularProgress;