/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:23:06
 * @modify date 2022-01-19 14:23:06
 * @desc [description]
 */

import React, { useCallback, useRef } from "react"; //lazy
// import { useHistory } from "react-router-dom";

/** Ant Lib*/
// import { Carousel } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import { WalletOutlined } from "@ant-design/icons";

/** Image */
import product_slider_1 from "assets/images/_product_slider/product_slide_1.png";
import product_slider_2 from "assets/images/_product_slider/product_slide_1.png";
import product_slider_3 from "assets/images/_product_slider/product_slide_1.png";
import product_slider_4 from "assets/images/_product_slider/product_slide_1.png";

/** Style Local */
import * as S from "./style";

interface Props {
  listArt?: any;
  goToDetail?: () => void;
}

const dataSlide = [
  {
    id: 0,
    img: product_slider_1,
  },
  {
    id: 1,
    img: product_slider_2,
  },
  {
    id: 2,
    img: product_slider_3,
  },
  {
    id: 3,
    img: product_slider_4,
  },
];

const ProductSlider = ({ listArt = dataSlide, goToDetail }: Props) => {
  const slider: any = useRef<any>(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    appendDots: (dots: any) => (
      <S.RowDots className="custom-dot">
        <S.Dots>{dots}</S.Dots>
        <S.ActionBackNext>
          <S.BackArrow onClick={() => backToSlide()}>
            <span className="ti ti-arrow-left"></span>
          </S.BackArrow>
          <S.NextArrow onClick={() => nextToSlide()}>
            <span className="ti ti-arrow-right"></span>
          </S.NextArrow>
        </S.ActionBackNext>
      </S.RowDots>
    ),
    customPaging: (i: any) => <S.DotsItem>0{i + 1}</S.DotsItem>,
  };

  /** Next to slide */
  const nextToSlide = useCallback(() => {
    slider.current.slickNext();
  }, []);

  /** Back to slide */
  const backToSlide = useCallback(() => {
    slider.current.slickPrev();
  }, []);

  return (
    <S.ProductSlider className="product-slider">
      <Slider ref={slider} {...settings}>
        {listArt &&
          listArt?.map((v: any, i: number) => (
            <S.SlideItem key={i} onClick={goToDetail}>
              {" "}
              {/** bg={v.img} */}
              <S.SlideItemImg src={v?.img ?? v} alt="slide-item-img" />
            </S.SlideItem>
          ))}
      </Slider>
    </S.ProductSlider>
  );
};

export default ProductSlider;
