/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:23:06
 * @modify date 2022-01-19 14:23:06
 * @desc [description]
 */

import React, { useMemo, useState } from "react"; //lazy
// import { useHistory } from "react-router-dom";

/** Components */
import { Heading, ErrorSpan, Select } from "components";

/** Config */
import moneyConvert from "utils/moneyConvert";

/** Style Local */
import * as S from "./style";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  authorName?: string;
  headingTitle?: string;
  artName?: string;
  artStatus?: string;
  artProgress?: number;
  artTotalAmount?: number;
  artTargetAmount?: number;
  artTimeRemaining?: number;
  actionButtonDisable?: boolean;
  actionButtonTitle?: string;
  actionButtonEvent?: () => void;
  listSubImage?: any;
  getQuantity?: any;
  errorContent?: string;
  // out sold
  isOutSold?: boolean;
  email?: string;
  oldPrice?: number;
  currentPrice?: number;
  remainingNumber?: any;
  isProcessing?: boolean;
  maxStock?: number;
  maxUnit?: number;
  typeRestock?: boolean;
  deliveryFee?: number;
}

function SaleGroup({
  authorName,
  headingTitle,
  artName,
  artProgress = 0,
  actionButtonDisable = false,
  actionButtonTitle,
  actionButtonEvent,
  listSubImage = [],
  getQuantity,
  errorContent,
  // out sold
  isOutSold,
  email,
  oldPrice,
  currentPrice,
  remainingNumber,
  // processing
  isProcessing,
  maxStock,
  maxUnit,
  typeRestock,
  deliveryFee,
}: Props) {
  const [currentItem, setCurrentItem] = useState<any>({
    id: 0,
    data: listSubImage[0] ?? "",
  });
  const { t } = useTranslation();

  useMemo(() => {
    setCurrentItem({ id: 0, data: listSubImage[0] ?? "" });
  }, [listSubImage]);

  return (
    <S.SaleTogetherContainer className="sale-group">
      <S.ListImage>
        {listSubImage.map((v: any, i: number) => (
          <S.ItemtImage
            className={currentItem.id === i ? "active" : ""}
            key={i}
            src={v}
            alt="image-item"
            onClick={() => setCurrentItem({ id: i, data: v })}
          />
        ))}
      </S.ListImage>
      <S.InfoData className="content-data">
        <S.LeftInfo>
          <S.ArtImage src={currentItem.data} alt="art-img" />
        </S.LeftInfo>

        <S.Content className="sale-together-info">
          <S.Head>
            {headingTitle && <Heading content={headingTitle} />}
            <S.TitleContent>
              {authorName}, {artName}
            </S.TitleContent>
          </S.Head>

          {isOutSold ? (
            <>
              <div style={{ marginBottom: "30px" }} />
              {oldPrice && oldPrice !== currentPrice && (
                <S.InfoTitle className="flex-layout gap-17 old-price">
                  <span className="text-light text-decoration">
                    {moneyConvertLang(oldPrice)}
                  </span>
                </S.InfoTitle>
              )}
              {currentPrice && (
                <>
                  <S.InfoTitle className="flex-layout gap-17">
                    <span className="text-medium">
                      <span className="text-price">상품금액</span>
                      {moneyConvertLang(currentPrice)}
                    </span>
                  </S.InfoTitle>
                  <S.InfoTitle className="flex-layout gap-17">
                    <div className="fee-transport text-medium">
                      <span className="text-price">배송비</span>
                      {moneyConvertLang(deliveryFee)}
                    </div>
                  </S.InfoTitle>
                </>
              )}
              <S.InfoTitle className="flex-layout gap-9 remanning-number">
                <span className="text-light">{t("QTY")}</span>
                <span className="text-medium">
                  {remainingNumber > 0 ? remainingNumber : t("SOLD_OUT")}
                </span>
              </S.InfoTitle>
              {/* <S.StockAmountInput
                id="stockInput"
                type="number"
                placeholder="스톡 수"
                max={10000}
              /> */}
              <S.ActionButton
                className={`${isOutSold ? "type-restock" : ""} action-button`}
                onClick={actionButtonEvent}
                disabled={actionButtonDisable}
              >
                {actionButtonTitle}
              </S.ActionButton>
            </>
          ) : isProcessing ? (
            <>
              {/** Processing */}
              {/* <S.InfoTitle>
                <span className="text-light">{email}</span>
              </S.InfoTitle> */}
              <div style={{ marginBottom: "30px" }} />
              {currentPrice && (
                <>
                  <S.InfoTitle className="flex-layout gap-17 processing-bw">
                    <span className="text-light">금액</span>
                    <span className="text-medium text-decoration">
                      <span className="text-price">상품금액</span>
                      {moneyConvertLang(currentPrice)}
                    </span>
                    <span className="text-medium text-percent">
                      -{artProgress}%
                    </span>
                  </S.InfoTitle>
                  <S.InfoTitle className="flex-layout gap-17">
                    <div className="fee-transport text-medium">
                      <span className="text-price">배송비</span>
                      {moneyConvertLang(deliveryFee)}
                    </div>
                  </S.InfoTitle>
                </>
              )}
              <S.InfoTitle className="flex-layout gap-9 processing-remanning">
                <span className="text-light">{t("QTY")}</span>
                <span className="text-medium">
                  {remainingNumber > 0 ? remainingNumber : t("SOLD_OUT")}
                </span>
              </S.InfoTitle>
              <S.ActionButton
                className={`${typeRestock ? "type-restock" : ""} action-button`}
                onClick={actionButtonEvent}
                disabled={actionButtonDisable}
              >
                {actionButtonTitle}
              </S.ActionButton>
              {/** ============= End Processing =========== */}
            </>
          ) : (
            <>
              {oldPrice && oldPrice !== currentPrice && (
                <S.InfoTitle className="flex-layout gap-17 old-price">
                  <span className="text-light text-decoration">
                    {moneyConvertLang(oldPrice)}
                  </span>
                  <span className="text-medium text-percent">
                    -{artProgress}%
                  </span>
                </S.InfoTitle>
              )}
              <S.InfoTitle
                className={`amount ${
                  oldPrice && oldPrice !== currentPrice ? "" : "orderHaveSlot"
                }`}
              >
                {/* {oldPrice !== currentPrice ? "할인가" : "판매가"} */}
                <span className="text-medium">
                  <span className="text-price">상품금액</span>
                  {moneyConvertLang(currentPrice)}
                </span>
              </S.InfoTitle>
              <S.InfoTitle className="flex-layout gap-17">
                <div className="fee-transport-modified text-medium">
                  <span className="text-price">배송비</span>
                  {moneyConvertLang(deliveryFee)}
                </div>
              </S.InfoTitle>
              <S.InfoTitle className="quantity flex-layout">
                <span className="text-light">{t("QTY")}</span>
                {/**(Quantity) */}
                <Select
                  maxStock={maxStock}
                  getSelectValue={getQuantity}
                  maxUnit={maxUnit}
                />
              </S.InfoTitle>
              <S.ActionButton
                className={`${typeRestock ? "type-restock" : ""} action-button`}
                onClick={actionButtonEvent}
                disabled={actionButtonDisable}
              >
                {actionButtonTitle}
              </S.ActionButton>
              {errorContent && <ErrorSpan content={errorContent} />}
            </>
          )}
        </S.Content>
      </S.InfoData>
    </S.SaleTogetherContainer>
  );
}

export default React.memo(
  SaleGroup,
  (prevProps, nextProps) => prevProps.listSubImage === nextProps.listSubImage
);
