import React from "react";
import * as S from "./style";
import { ReactComponent as AngleDown } from "assets/icons/angle-down.svg";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import types from "store/actions/types";
const { Option } = Select;

type dataOption = {
  text: string;
  value: string;
};

interface Props {
  data: dataOption[];
  handleChange?: (e: string) => void;
  typeGroupSale?: boolean;
}

function SelectLeftMenu({ data, handleChange, typeGroupSale }: Props) {
  const dispatch = useDispatch();

  const handleChangeSelect = (value: any) => {
    if (typeGroupSale) {
      handleChange?.(value);
    } else {
      dispatch({
        type: types.SELECT_BAR,
        selectValue: value,
      });
    }
  };

  return (
    <S.Selects
      defaultValue={data[0]?.value}
      onChange={handleChangeSelect}
      suffixIcon={<AngleDown />}
      dropdownMatchSelectWidth={false}
    >
      {data.map((dt, index) => (
        <Option value={dt.value} key={index}>
          {dt.text}
        </Option>
      ))}
    </S.Selects>
  );
}

export default SelectLeftMenu;
