import React, { useCallback, useEffect } from "react";
import * as S from "./style";

interface Props {
  valueDefault?: any;
  listOption?: any[];
  getSelectValue?: (e: any) => void;
  maxStock?: number;
  maxUnit?: number;
}

function Select({
  valueDefault,
  listOption,
  getSelectValue,
  maxStock,
  maxUnit = 20,
}: Props): React.ReactElement {
  useEffect(() => {
    getSelectValue?.(valueDefault ?? 1);
  }, [valueDefault]);

  const getListStock = useCallback(() => {
    if (maxStock) {
      const availableSlot = Math.min(...[maxStock, maxUnit]);
      let i: number = 1;
      const listNumber = [];
      for (i = 1; i <= availableSlot; i++) {
        listNumber.push(i);
      }
      return listNumber;
    }
    return listOption ?? [];
  }, [maxStock]);

  return (
    <S.SelectCustom
      defaultValue={valueDefault ?? getListStock()[0]}
      suffixIcon={<span className="ti ti-angle-down"></span>}
      onChange={getSelectValue}
      notFoundContent={false}
    >
      {getListStock()?.map((v: any, i: number) => (
        <S.SelectItem key={v}>{v}</S.SelectItem>
      ))}
    </S.SelectCustom>
  );
}

export default Select;
