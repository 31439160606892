import styled from "styled-components";

export const Tabs = styled.div<{ isCompartment?: boolean }>`
  border-bottom: 1px solid #c6cdc7;
  text-align: center;
  flex: 0 0 50%;
  font-family: "Montserrat-Light";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.45px;
  color: #5d7160;
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
  &.active {
    color: #5d7160;
    font-family: "Montserrat-SemiBold";
    /* border-bottom: 3px solid #ad9a6d; */
    font-weight: 600;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -2px;
      height: 3px;
      width: 65px;
      background-color: #5d7160;
    }
  }

  &:nth-child(1) {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 28px;
      border-right: ${({ isCompartment }) =>
        isCompartment ? "1px solid #c6cdc7" : "none"};
    }
  }
`;
