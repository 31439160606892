import styled from "styled-components";

export const HeadPopup = styled.img`
  width: 100%;
  height: 30px;
  object-fit: fill;
  /* position: absolute */
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* position: relative; */
  /* padding-top: 37px; */
  h2 {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 0.7px;
    text-align: center;
    color: #5c7161;
    margin: 0;
    font-family: "Montserrat-Medium";
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  button {
    min-width: 155px;
    height: 45px;
    margin: 22px 0 0;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #5c7161;
    border: #5c7161;

    &:hover {
      background-color: #5c7161;
      border: #5c7161;
    }

    @media screen and (max-width: 768px) {
      width: 300px;
      height: 45px;
    }
  }
  &.not_sale {
    /* padding-right: 90px; */
    button {
      margin: 27px 0 0;
    }
  }
`;

export const Content = styled.div`
  padding: 37px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  word-break: keep-all;
  h2 {
    word-break: break-all;
  }
`;
