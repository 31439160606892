import types from "../actions/types";

const defaultState = {
  dataCategoriesMarket: [],
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.GET_CATEGORY_MARKET:
      return {
        ...state,
        dataCategoriesMarket: action.dataCategoriesMarket,
      };
    default:
      return state;
  }
};
