import { Spin } from "antd";
import React from "react";
import * as S from "./style";

function Loading(): React.ReactElement {
  return (
    <S.Container>
      <Spin size="large" />
    </S.Container>
  );
}

export default Loading;
