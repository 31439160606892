import React from "react";
import Lottie, { Options } from "react-lottie";
import LoadingJson from "assets/images/Loading.json";

import * as S from "./style";

function LoadingLottie(): React.ReactElement {
    const defaultOptions: Options = {
        loop: true,
        autoplay: true,
        animationData: LoadingJson,
        rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    };
    return (
        <S.Container>
            {/* <Spin size="large" /> */}
            <Lottie options={defaultOptions} height={400} width={400} />
        </S.Container>
    );
}

export default LoadingLottie;