/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:33:01
 * @modify date 2022-01-19 14:33:01
 * @desc [description]
 */

import { Button } from "antd";
import styled from "styled-components";

interface ItemProps {
  bg?: any;
}

export const SaleTogetherContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .product-slider {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .box-time {
    display: inline-block;
    margin-left: 30px;
    .time {
      display: inline-block;
      text-align: center;
      border-radius: 5px;
      border: solid 1px #5c7161;
      width: 36px;
      height: 32px;
      line-height: 32px;
      margin-left: 15px;
      position: relative;
      font-family: "Montserrat-SemiBold";
      font-size: 20px;
      font-weight: 600;
      &::after {
        content: ":";
        position: absolute;
        right: -11px;
        top: 0;
        bottom: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }
`;

export const InfoData = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 200px);
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 41.2px;
  /* margin: 60.6px 0px 12px 0px; */
  .ant-progress-inner {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 16px;
    padding: 0;
  }
  /* @media (max-width: 767px) {
    padding: 0;
  } */
  .tag--event {
    border-radius: 5px;
    background-color: #ff1744;
    width: 55px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-family: "Montserrat-SemiBold";
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export const TitleContent = styled.span`
  width: 100%;
  font-size: 45px;
  font-size: 28px;
  font-family: "Montserrat-Medium";
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #5c7161;
`;

export const SubTitleContent = styled.span`
  font-size: 18px;
  font-family: "Montserrat-Light";
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
  color: #ad9a6d;
`;

export const InfoTitle = styled.span`
  font-size: 22px;
  font-family: "Montserrat-Medium";
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.55px;
  color: #5c7161;

  &.progress {
    margin: 40px 0px 14px 0px;
  }

  &.amount-total {
    margin: 44px 0px 24px 0px;
  }

  &.amount-event {
    margin: 45px 0 5px;
  }

  &.time-remaining {
    margin: 23px 0px 36px 0px;
  }

  .text-medium {
    font-weight: 600;
    font-family: "Montserrat-SemiBold";
  }
`;

export const ActionButton = styled(Button)`
  width: 100%;
  height: 65px;
  background-color: #5c7161;
  font-family: "Montserrat-Medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  &:hover,
  :active,
  :focus {
    background-color: #5c7161;
    border-color: #5c7161;
    color: #ffffff;
  }

  &[disabled] {
    color: #ffffff;
    background-color: #c6cdc7;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ListImage = styled.div`
  max-width: 186.3px;
  margin-right: 30.7px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15.5px;

  &.ui-768 {
    display: none;
    margin-bottom: 69.1px;
    margin-top: 0px;

    @media screen and (max-width: 768px) {
      display: unset;
      height: auto;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: row;
    max-width: 100%;
    margin-bottom: 20px;
    gap: 15px;
    margin-right: 0px;
    /* padding: 0 10px; */
  }
  @media screen and (max-width: 768px) {
    gap: 11px;
    /* justify-content: center; */
  }

  div {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  }

  &.mrt-80 {
    margin-top: 80px;
  }
`;

export const ItemtImage = styled.img`
  width: 100%;
  max-width: 186.3px;
  /* height: 250px; */
  object-fit: contain;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &.active {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  }
  @media screen and (max-width: 768px) {
    max-width: 100px;
  }
  /* @media screen and (max-width: 768px) {
    width: 155px;
    height: auto;
    min-height: 250px;
  } */
`;

export const LeftInfo = styled.div<{ bg?: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  /* min-height: 590px; */
  overflow: hidden;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 100%;
  background-position: center;
  object-fit: contain;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    /* width: auto;
    height: auto; */
    max-width: 650px;
    margin: 20px auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    max-width: 650px;
    margin: 20px auto;
  }

  @media screen and (max-width: 425px) {
    height: unset;
  }
`;

export const ArtImage = styled.img`
  width: 100%;
  /* height: 100%; */
  /* object-fit: contain; */
  transition: all 0.3s;
  transition-timing-function: ease;
  object-fit: cover;

  @media screen and (max-width: 1024px) {
    object-fit: contain;
  }
  @media screen and (max-width: 425px) {
    object-fit: contain;
  }
`;
