import styled, { css } from "styled-components";
import { Modal } from "antd";

export const ModalPopup = styled(Modal)<{ top?: string }>`
  top: ${({ top }) => top ?? "200px"};
  @media screen and (max-width: 1024px) {
    top: 150px;
  }
  @media screen and (max-width: 767px) {
    top: 100px;
  }
`;

export const Container = styled.div`
  position: relative;
  padding-top: 30px;
  padding-bottom: 20px;
  min-width: 250px;
  max-width: 348px;
  text-align: center;
  font-family: "Montserrat-Medium";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #5c7161;

  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  > span {
    margin: auto;
  }

  &.otherpage-popup-container {
    padding-top: 15px;

    &.term-popup {
      max-width: 750px;
      max-height: 712px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }

      button {
        max-width: 250px;
      }
    }
  }

  h2 {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ad9a6d;
    margin: 0;
  }
  button {
    border: none;
    width: 155px;
    height: 45px;
    text-align: center;
    font-family: "Montserrat-Medium";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

export const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-40%, -50%);
`;

export const ButtonConfirm = styled.button`
  background-color: #5d7160;
  color: #ffffff;
  font-family: "Montserrat-Medium";
  font-size: 16px;
  font-weight: 500;
  &.mrl-20 {
    margin-left: 20px;
    @media screen and (max-width: 768px) {
      width: 158px !important;
    }
  }
  &.min {
    width: 155px !important;
    margin: 0 auto;
  }
`;

export const ButtonCancel = styled.button`
  border: solid 1px #5d7160 !important;
  background-color: #ffffff;
  color: #5d7160;

  &.otherpage-popup-cancel {
    background-color: #eeeeee;
    border: none !important;
    @media screen and (max-width: 768px) {
      width: 141px !important;
    }
  }
`;

export const WrapperButton = styled.div<{
  isNotMarginTop?: boolean;
  mypage?: boolean;
  onlyOk?: boolean;
}>`
  display: flex;
  justify-content: ${({ onlyOk }) => (onlyOk ? "center" : "space-between")};
  margin-top: ${({ isNotMarginTop }) => (isNotMarginTop ? "0px" : "30px")};
  gap: ${({ mypage }) => (mypage ? "20px" : "0px")};
  > button {
    ${(props) => {
      if (props.onlyOk) {
        return css`
          margin: 0 !important;
          flex: 0 0 calc(100% - 10px);
        `;
      }
      if (props.mypage) {
        return css`
          margin: 0 !important;
          flex: 0 0 calc(50% - 10px);
        `;
      }
    }}
  }
  &.min {
    width: 155px !important;
    margin: 30px auto 16px;
  }
`;
