import React, { useState } from "react";
import { CheckboxProps } from "antd";
import * as S from "./style";

interface Props extends CheckboxProps {
  children?: any;
  colorText?: string;
  handleCheck?: (e: boolean) => void;
}

function CheckBox({
  children,
  colorText,
  handleCheck,
  ...props
}: Props): React.ReactElement {
  const [active, setActive] = useState(false);
  return (
    <S.CheckBox
      {...props}
      colorText={colorText}
      active={active}
      onChange={(e) => {
        setActive(e.target.checked);
        handleCheck?.(e.target.checked);
      }}
    >
      {children}
    </S.CheckBox>
  );
}

export default CheckBox;
