import { Image } from "antd";
import { UrlInternal } from "common/constants/endpoints";
import React from "react";
import { useHistory } from "react-router-dom";
import Kakao from "assets/images/iconFooter/kakao-talk-icon.png";
import Youtube from "assets/images/iconFooter/youtube-icon.png";
import Insta from "assets/images/iconFooter/instagram-icon.png";
import * as S from "./style";
import { useTranslation } from "react-i18next";

function FooterMain() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <S.Wrapper id="footer">
      <S.Container>
        <div className="step">
          <S.Title>COMPANY</S.Title>
          <S.Box>
            <S.Text>{t("FOOTER_COMPANY_TITLE")}</S.Text>
            <S.Text>{t("FOOTER_COMPANY_TITLE1")}</S.Text>
            <div>
              <S.Text>{t("FOOTER_COMPANY_TITLE2")}</S.Text>
              <S.Text className="text-modified">
                {t("FOOTER_COMPANY_TITLE2A")}
              </S.Text>
            </div>
            <S.Text className="text-modified">
              {t("FOOTER_COMPANY_TITLE3")}
            </S.Text>
          </S.Box>
          <S.TextModified>
            <span
              onClick={() => {
                history.push(UrlInternal.ART_LINK_AGREEMENT);
              }}
            >
              {t("FOOTER_PAGE_TERM")}
            </span>{" "}
            |{" "}
            <span
              onClick={() => {
                history.push(UrlInternal.ART_LINK_BUYER);
              }}
            >
              {t("FOOTER_PAGE_BUYER")}
            </span>{" "}
            |{" "}
            <span
              onClick={() => {
                history.push(UrlInternal.ART_LINK_SELLER);
              }}
            >
              {t("FOOTER_PAGE_SELLER")}
            </span>{" "}
            |{" "}
            <span
              onClick={() => {
                history.push(UrlInternal.ART_LINK_PRIVACY);
              }}
            >
              {t("FOOTER_PAGE_PRIVACY")}
            </span>
          </S.TextModified>
          <S.TextMin>© Yeolmaecompany. all rights reserved</S.TextMin>
        </div>
        <div className="step">
          <S.Title>BANK INFO</S.Title>
          <S.Box>
            <S.Text>
              {t("FOOTER_BANK_INFO")} : 2021-{t("FOOTER_BANK_INFO5")}
            </S.Text>
            <S.Text>{t("FOOTER_BANK_INFO1")}</S.Text>
            <S.Text className="stock-company">
              {t("FOOTER_BANK_INFO2")} : {t("FOOTER_BANK")} 033201-04-177604
            </S.Text>
            <S.Text>{t("FOOTER_BANK_INFO3")}</S.Text>
            <S.Text className="stock-company">
              {t("FOOTER_BANK_INFO4")} : {t("FOOTER_BANK")} 350401-04-149344
            </S.Text>
            <S.Text>{t("FOOTER_BANK_INFO3")}</S.Text>
          </S.Box>
        </div>
        <div className="step">
          <S.Title>CONTACT</S.Title>
          <S.Box className="box-modified">
            {/* <S.Text>070-5015-0773</S.Text> */}
            <S.Text>info@artnguide.com</S.Text>
          </S.Box>
          <S.Title className="title-modified">HOURS</S.Title>
          <S.Text>
            {t("FOOTER_CONTACT")} 10:00~18:00 ({t("FOOTER_CONTACT1")}{" "}
            12:00~13:00)
          </S.Text>
          <S.IconContainer>
            <Image
              preview={false}
              src={Youtube}
              alt="Youtube"
              onClick={() => window.open("https://www.youtube.com/c/Artnguide")}
            />
            <Image
              preview={false}
              src={Insta}
              alt="Insta"
              onClick={() =>
                window.open("https://www.instagram.com/artnguide/")
              }
            />
            <Image
              preview={false}
              src={Kakao}
              alt="Kakao"
              onClick={() => window.open("https://pf.kakao.com/_ixgPNj")}
            />
          </S.IconContainer>
        </div>
      </S.Container>
    </S.Wrapper>
  );
}

export default FooterMain;
