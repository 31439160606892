/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-27 15:02:33
 * @modify date 2022-01-27 15:02:33
 * @desc [description]
 */

import styled from "styled-components";

export const Content = styled.span`
  font-family: "NotoSansKR-Light";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.35px;
  text-align: left;
  color: #df0808;
  margin-top: 16px;
  word-break: keep-all;
`;
