import { mainAxios } from "libs/axios";

export const getUserApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/user/details`,
    requiresToken: true,
    notError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Update info Props type */
interface updateInfoProps {
  email?: string;
  phoneNumber?: string;
  smsReceive?: boolean;
  emailReceive?: boolean;
  biZipCode?: string;
  biAddress?: string;
  biAddress2?: string;
  biBankAccount?: string;
  biBankHolderName?: string;
  biBankName?: string;
  birthDate?: string;
  displayName?: string;

  // For company information
  companyZipCode?: string;
  companyAddress?: string;
  companyAddress2?: string;
}

export const updateInfoApi = (payload: updateInfoProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/user/update-info`,
    payload: payload,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Point history Props type */
interface pointHistoryProps {
  page?: number;
  size?: number;
}

export const pointHistoryApi = (params: pointHistoryProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/user/point-history`,
    requiresToken: true,
    params: params,
    notError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Faq history Props type */
interface faqHistoryProps {
  page?: string;
  size?: string;
  query?: string;
  startTime?: string;
  endTime?: string;
}

export const faqHistoryApi = (payload: faqHistoryProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/qna/get-qna-list`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Create Faq history Props type */
interface createFaqHistoryProps {
  title?: string;
  content?: string;
  attachments?: string;
  categoryCode?: string;
}

export const createFaqHistoryApi = (payload: createFaqHistoryProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/qna/send-qna`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface updateFaqHistoryApi {
  qnaId?: string;
  title?: string;
  content?: string;
  attachments?: string;
  // categoryCode?: string;
}
export const updateFaqHistoryApi = (payload: updateFaqHistoryApi) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/qna/edit-qna`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** Delete QNA */
interface deleteQNAProps {
  qnaId: number;
}
export const deleteQNA = (payload: deleteQNAProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/qna/delete-qna`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Together history props type */
interface togetherHistoryProps {
  page?: number;
  size?: number;
  querySearch?: string;
  status?: string;
  type?: string;
  id?: number;
  gpProgressFilter?: string;
}

export const togetherHistoryApi = (params: togetherHistoryProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/my-order`,
    requiresToken: true,
    params: params,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
  });
};

/**Cancel Buy Together history props type */
interface togetherCancelpayProps {
  id?: number;
  reason?: string;
  bankName?: string;
  bankAccount?: string;
  bankHolderName?: string;
}

export const togetherCancelPayApi = (payload: togetherCancelpayProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/gp/req-cancel-order`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    // notError: true,
  });
};

/**Art Shopping history props type */
interface artShoppingHistoryProps {
  page?: number;
  size?: number;
  querySearch?: string;
  status?: string;
}

export const artShoppingHistoryApi = (params: artShoppingHistoryProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/market/list-order`,
    requiresToken: true,
    params: params,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
  });
};

/**Detail Art Shopping history props type */
interface detailArtHistoryProps {
  id: number;
}

export const detailArtHistoryApi = (params: detailArtHistoryProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/market/order-details`,
    requiresToken: true,
    params: params,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
  });
};

/**Cancel Order Detail Art Shopping history props type */
interface cancelOrderArtHistoryProps {
  id: number;
  cancelReason?: string;
  bankName?: string;
  bankAccount?: string;
}

export const cancelOrderArtHistoryApi = (
  payload: cancelOrderArtHistoryProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/market/cancel-order`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Return Order Detail Art Shopping history props type */
interface returnOrderArtHistoryProps {
  id: number;
}

export const returnOrderArtHistoryApi = (
  payload: returnOrderArtHistoryProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/market/return-order`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

//cancel member
export const cancelMemberApi = () => {
  return mainAxios.request({
    methodType: "POST",
    url: `/user/leave`,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** Appointment History */
interface appointmentHistoryProps {
  querySearch?: string;
  size?: number;
  page?: number;
  startDate?: string;
  endDate?: string;
  status?: string;
  id?: number;
}

export const getAppointmentHistory = (params: appointmentHistoryProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/esr/find-all`,
    requiresToken: true,
    params,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** Appointment History */
interface appointmentHistoryCancelProps {
  id?: number;
}

export const appointmentCancelHistory = (
  payload: appointmentHistoryCancelProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/esr/cancel`,
    requiresToken: true,
    getError: true,
    notError: true,
    payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** get category */
interface getDataCategory {
  size?: string;
  page?: string;
  query?: string;
}

export const getCategoryApi = (payload: getDataCategory) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/qna/category-list`,
    requiresToken: true,
    payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const getStatisticApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/ogp-statistic`,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
  });
};

/**Together history props type */
interface mySerialProps {
  page?: number;
  size?: number;
  querySearch?: string;
}

export const mySerialApi = (params: mySerialProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/ownership-manager/my-serial-gp`,
    requiresToken: true,
    params: params,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
  });
};

/**Together history props type */
interface applyCouponProps {
  code?: string;
  bankName?: string;
  bankAccount?: string;
  bankHolderName?: string;
}

export const applyCouponApi = (payload: applyCouponProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/pce/using-coupon`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
    getError: true,
  });
};
