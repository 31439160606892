import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  padding-top: 30px;
  h2 {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ad9a6d;
    margin: 0;
  }
`;

export const Image = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(45%, 45%);
`;
