import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #9bb29f;
  display: flex;
  align-items: center;
  justify-content: center;
  &.footer-none {
    display: none;
  }
`;

export const Container = styled.div`
  margin: 0px;
  width: 100%;
  max-width: 1350px;
  padding: 75px 0 60px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  /* .step2 {
    padding-left: 38px;
  }
  .step3 {
    padding-left: 154px;
  } */
  .step {
    padding: 0 2%;

    @media screen and (max-width: 1280px) {
      justify-content: unset;
      gap: 2%;
      padding: 0px;
      &:first-child {
        padding: 0 0 0 20px;
      }
      &:last-child {
        padding: 0 20px 0 0;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 40px;
      margin-top: 46px;
      &:first-child {
        padding: 0 40px;
      }
      &:last-child {
        padding: 0 40px;
      }
    }
    @media screen and (max-width: 540px) {
      padding: 0 25px;
      &:first-child {
        padding: 0 25px;
      }
      &:last-child {
        padding: 0 25px;
      }
    }
    @media screen and (max-width: 360px) {
      padding: 0 2px 0 15px;
      &:first-child {
        padding: 0 2px 0 15px;
      }
      &:last-child {
        padding: 0 2px 0 15px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 25px 0 60px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  &.box-modified {
    gap: 12px;
    margin-bottom: 26px;
  }
  &.box-modifies {
    gap: 13px;
  }
`;

export const Title = styled.div`
  font-family: "Montserrat-Bold";
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: -0.63px;
  color: #fff;
  margin-bottom: 19px;
  &.title-modified {
    margin-bottom: 13px;
  }
`;

export const Text = styled.div`
  font-family: "Montserrat-Medium";
  font-size: 17px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -1.02px;
  color: #fff;
  &.text-modified {
    margin-top: 6px;
  }

  &.stock-company {
    margin-top: 25px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.7px;
  }
`;

export const TextModified = styled.div`
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: -0.9px;
  color: #fff;
  margin: 43px 0 9px;
  > span {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 1.27;
    letter-spacing: -0.75px;
  }
  @media screen and (max-width: 360px) {
    padding-right: 90px;
  }
`;

export const TextMin = styled.div`
  font-family: "Montserrat-Light";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.22;
  letter-spacing: -0.75px;
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 1.27;
    letter-spacing: -0.7px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 45px;
  > div {
    margin-right: 20px;
    padding: 9px 8px 8px 9px;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
  }
`;
