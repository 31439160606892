import React from "react";
import * as S from "./style";

interface Props {
  content?: string
}

const SubTitle = (props: Props) => {
  const { content } = props;
  return (
    <S.Content className="sub-title">{content}</S.Content>
  );
}

export default SubTitle;
