/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-05-11 11:00:24
 * @modify date 2022-05-11 11:00:24
 * @desc [description]
 */

import styled from "styled-components";

export const Container = styled.span`
  width: auto;
  counter-reset: chapter;
  counter-increment: chapter;
  transition: all 1s;
`;
